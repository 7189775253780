<template>
  <div
    :style="{
      width,
      height,
      opacity: (isActive) ? 1 : 0.6,
    }"
    class="sd-video"
  >
    <component
      :is="source"
      :id="id"
      :channel="channel"
      :width="width"
      :height="height"
      :ref="source"
      :streamType="source"
      :quality="quality"
      class="sd-video__iframe"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_MUTED_STREAMS } from '../store/helpers/mutationTypes';
import { isDesktop } from '../mixins/WindowUtils';
import Twitch from './Twitch';
import Youtube from './Youtube';

export default {
  name: 'Stream',
  mixins: [isDesktop],
  components: {
    Twitch,
    Youtube,
  },
  data() {
    return {
      width: '100%',
      height: '100%',
    };
  },
  props: {
    channel: {
      type: String,
    },
    source: {
      type: String,
    },
    isActive: Boolean,
    id: String,
    quality: Object,
  },
  computed: {
    ...mapGetters([
      'mutedStreams',
    ]),
    player() {
      return this.$refs[this.source];
    },
  },
  watch: {
    /**
     * @description Mute/unmute our stream based off the 'mutedStreams'
     * key in our store
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.0
     */
    mutedStreams(mutedStreams) {
      if (mutedStreams.includes(this.id)) {
        this.muteVideo();
      } else {
        this.unMuteVideo();
      }
    },
    /**
     * @description Sync the custom controls with the native controls. We have to update
     * the store whenever we switch controls because we do not have a native event
     * to update/sync the controls.
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    isDesktop() {
      if (this.isActive && this.player.isMuted) {
        // update our 'mutedStreams' value in the store
        this.$store.commit(UPDATE_MUTED_STREAMS, {
          id: this.id,
          value: this.player.isMuted(),
        });
      }
    },
  },
  methods: {
    /**
     * @description Play our video feed
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.0
     */
    playVideo() {
      if (this.player) {
        this.player.play();
      }
    },
    /**
     * @description Pause our video feed
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.0
     */
    pauseVideo() {
      if (this.player) {
        this.player.pause();
      }
    },
    /**
     * @description Mute our video feed
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.0
     */
    muteVideo() {
      this.player.mute();
    },
    /**
     * @description Unmute our stream
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.0
     */
    unMuteVideo() {
      this.player.unMute();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/mixins/utils';
  @import '@/assets/styles/components/video';
</style>
