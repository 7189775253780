import eventBus from '../utils/EventBus';

/**
 * Default props and functions for our modals
 *
 * @param varName
 * @returns {string}
 *
 * @since 1.0.0
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  created() {
    // Create the event listener to toggle our modal
    eventBus.$on('toggle-visibility', (modalId) => this.toggleModal(!this.isVisible, modalId));
  },
  beforeDestroy() {
    // Remove the event listener
    eventBus.$off('toggle-visibility', (modalId) => this.toggleModal(!this.isVisible, modalId));
  },
  methods: {
    /**
     * Toggle our modal
     *
     * @param isVisible
     * @param modalId
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    toggleModal(isVisible, modalId = this.id) {
      if (modalId === this.id) {
        this.isVisible = isVisible;
      }
    },
  },
};
