<template>
  <footer class="sd-footer">
    <span
      v-show="isDesktop"
      class="sd-footer__content">
      <Icon
        src="logo-silent-disco"
        width="107px" />
    </span>

    <div class="sd-footer__content">
      <!-- Display our typeform link -->
      <a
        class="sd-link"
        href="https://brthrsagency.typeform.com/to/oVt8Kk"
        target="_blank">
        {{ $t('contactUs') }}
      </a>

      <!-- Display our privacy link -->
      <a
        class="sd-link"
        :href="$t('privacyLink')"
        target="_blank">
        {{ $t('privacy') }}
      </a>

      <!-- Display our copyright mention -->
      <p v-if="isBreakpointLG">{{ $t('copyright') }}</p>
    </div>

    <span class="sd-footer__content">
      <!-- Display our modals -->
      <InfoModal />
      <ShareModal />

      <a
        class="sd-footer__button--share sd-pill--primary"
        @click="emitGlobalEvent('toggle-visibility', 'shareModal')">
        <Icon src="share" />
        <span>{{ $t('share') }}</span>
      </a>
      <a
        class="sd-footer__button sd-pill--white sd-pill--2-square"
        @click="emitGlobalEvent('toggle-visibility', 'infoModal')">
        <Icon src="info-dark" />
      </a>

      <a
        class="sd-footer__button sd-pill--instagram sd-pill--1-square"
        alt="Instagram"
        href="https://www.instagram.com/silentdiscobreak/"
        target="_blank">
          <Icon
            src="instagram"
            width="32px"/>
      </a>
    </span>

  </footer>
</template>

<script>
import GlobalEventHelper from '../../mixins/GlobalEventHelper';
import { Dimensions, getCssVariable, isDesktop } from '../../mixins/WindowUtils';
import Icon from '../../components/Icon';
import InfoModal from '../../modals/InfoModal';
import ShareModal from '../../modals/ShareModal';

export default {
  name: 'Footer',
  mixins: [GlobalEventHelper, isDesktop, Dimensions, getCssVariable],
  components: {
    Icon,
    InfoModal,
    ShareModal,
  },
  computed: {
    /**
     * Add a custom breakpoint for our modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    isBreakpointLG() {
      return this.windowWidth > parseInt(this.cssVar('breakpoint-lg'), 10);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/mixins/button';
  @import '@/assets/styles/components/pill';

  .sd-footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding: $header-padding-mobile;
    font-size: $font-size-base;
    color: $color-white;
    background-color: $color-black;

    @include media-breakpoint-up(md) {
      padding: $header-padding;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      align-items: stretch;
    }

    &__content {
      @mixin base-content {
        justify-content: flex-start;
        font-size: $font-size-base;
        line-height: $font-size-xl;
        user-select: none;

        &:first-child {
          display: flex;
          flex: 1;
        }

        &:last-child {
          display: flex;
          flex: 1;

          @include media-breakpoint-up(md) {
            justify-content: flex-end;
          }
        }

        &:nth-child(n) {
          @include media-breakpoint-down(md) {
            justify-content: space-between;
          }
        }

        &:nth-child(n+2):not(:last-child) {
          display: flex;
          flex: 1 0 auto;
          align-items: center;
          justify-content: center;
          padding: {
            top: map-get($spacers, 1);
            bottom: map-get($spacers, 1);
          }

          // Remove the top/bottom margin off our paragraphs
          > :nth-child(n) {
            margin: {
              top: 0;
              bottom: 0;
            }
          }

          // Separate our 'nav' items with bullets/dots
          > :nth-child(n):not(:last-child) {
            $dot-radius: $carousel-dot-radius / 2;
            $dot-spacer: map-get($spacers, 1);

            position: relative;
            margin-right: $dot-radius + $dot-spacer * 2;

            &::after {
              display: inline-flex;
              width: $dot-radius;
              height: $dot-radius;
              content: "";
              background-color: $carousel-dot-color;
              border-radius: 50%;
              transform: translate(#{$dot-spacer}, -50%);
            }
          }
        }
      }

      @include base-content;
    }

    &__button {
      $parent: &;

      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      cursor: pointer;
      transition: $link-transition;

      > :nth-child(n):not(:last-child) {
        margin-right: map-get($spacers, 2);
      }

      &[disabled] {
        cursor: not-allowed;
        filter: brightness(35%);
      }

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        filter: brightness(75%);
      }

      &:not([disabled]):active {
        filter: brightness(70%);
      }

      &:not(:last-child) {
        margin-right: map-get($spacers, 5);
      }

      &--share {
        @extend #{$parent};

        @include media-breakpoint-down(md) {
          flex: 1;
          justify-content: center;
        }
      }
    }
  }
</style>
