/**
 * @file
 * @description Helper functions
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */

/**
 * @description Returns the default state
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export default () => ({
  breakId: '',
  break: null,
  activeStream: { streamId: 'silentdiscobreak' },
  mutedStreams: [],
});
