<template>
  <aside
    v-if="isBreakpointLG && shouldLoad && !chatService"
    v-show="isVisible"
    class="sd-chat">
    <div class="sd-chat__header">
      <sd-header
        :type="5"
        font-weight="medium"
        :offset-bottom="false"
        weight="medium">{{ activeStream('streamId') | capitalize }}</sd-header>

      <span
        class="sd-shape-cross"
        @click="closeChat"/>
    </div>

    <iframe
      v-for="stream in streams"
      v-show="activeStream('id') === stream.id"
      :key="stream.id"
      :id="stream.streamId"
      :src="`https://www.twitch.tv/embed/${stream.streamId}/chat?darkpopout&parent=${getBaseUrl}`"
      frameborder="0"
      scrolling="yes"
      class="sd-chat__body"/>
  </aside>

  <!-- Display our chat modal only for small devices and when a chatService is not defined -->
  <chat-modal v-else-if="!chatService"/>
</template>
<script>
/**
 * @file
 * @description Display twitch chat
 *
 * @author Thiago Fazzi (@ThiagoFazzi)
 * @author Robin Eijsbouts (@RobinEijsbouts)
 * @since 1.0.3
 */
import { mapGetters } from 'vuex';
import { isDesktop, Dimensions, getCssVariable } from '../mixins/WindowUtils';
import GlobalEventHelper from '../mixins/GlobalEventHelper';
import sdHeader from './Header';
import eventBus from '../utils/EventBus';
import { capitalize } from '../utils/Filters';
import ChatModal from '../modals/ChatModal';

export default {
  name: 'Chat',
  mixins: [isDesktop, Dimensions, getCssVariable, GlobalEventHelper],
  components: {
    ChatModal,
    sdHeader,
  },
  filters: {
    capitalize,
  },
  created() {
    // Create the event listener to open our chat component
    eventBus.$on('open-chat', () => this.openChat());
  },
  beforeDestroy() {
    // Remove the event listener
    eventBus.$off('open-chat', () => this.openChat());
  },
  data() {
    return {
      isVisible: false,
      shouldLoad: false,
    };
  },
  computed: {
    ...mapGetters([
      'streams',
      'activeStream',
      'chatService',
    ]),
    /**
     * Add a custom breakpoint for our modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    isBreakpointLG() {
      return this.windowWidth > parseInt(this.cssVar('breakpoint-lg'), 10);
    },
    getBaseUrl() {
      return window.location.hostname;
    },
  },
  methods: {
    /**
     * @description Open Chat for either mobile or desktop
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    openChat() {
      // check if break has different chatService than Twitch chat
      if (this.chatService) {
        // open the chat service in another page/tab
        window.open(this.chatService, '_blank');
      } else if (this.isBreakpointLG) {
        // enable the chat component also load the chat rooms
        this.shouldLoad = true;
        this.isVisible = true;
      } else {
        // open chat modal for mobile
        this.emitGlobalEvent('toggle-visibility', 'chatModal');
      }
    },
    closeChat() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/mixins/cross';

// Display our cross
.sd-shape-cross {
  @include cross($color: $color-white, $radius: 24px, $diagonal-width: 4px);
}

.sd-chat {
  $header-width: 21rem;

  display: flex;
  flex-direction: column;
  width: $header-width;
  background-color: $modal-background;

  &__header {
    display: flex;
    align-items: center;
    padding: map-get($spacers, 3);

    > :not([class^="sd-shape"]) {
      flex: 1;
    }
  }

  &__body {
    flex: 1;
  }
}
</style>
