<template>
  <transition name="sd-fade-scale">
    <div
      class="sd-modal__backdrop"
      @click="canBackdropDismiss ? $emit('close') : null">
      <div
        :style="modalWrapperStyle"
        :class="[`sd-modal-content${modalWrapperVariant}`, ...segmentClass.wrapper].join(' ')"
        @click.stop>

        <!-- Modal header -->
        <div
          v-if="this.$slots.header || title"
          :class="segmentClass.header">

          <!-- Display our 'basic' header -->
          <template v-if="!this.$slots.header && title">
            <h5 class="sd-modal__title">{{ title }}</h5>
            <span
              class="sd-modal-close"
              @click="$emit('close')"
            />
          </template>

          <!-- Display our custom/slot header -->
          <slot v-else name="header" />
        </div>

        <!-- Modal body slot -->
        <div
          v-if="this.$slots.body"
          :class="segmentClass.body">
          <slot name="body" />
        </div>

        <!-- Modal footer slot -->
        <div
          v-if="this.$slots.footer"
          :class="segmentClass.footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * @file
 * @description Modal layout as a container for all modals
 * @author Femke Buijs
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export default {
  props: {
    /**
     * Set modal title
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Set a minimum width to our modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    minWidth: {
      type: [String, Number],
      default: 'auto',
    },
    classes: {
      type: Object,
      default: null,
    },
    /**
     * Boolean to check if our backdrop can dismiss our modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    canBackdropDismiss: {
      type: Boolean,
      default: true,
    },
    /**
     * Select alternate variants of our modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    variant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultClasses: {
        wrapper: [],
        header: ['sd-modal-content__header'],
        body: ['sd-modal-content__body'],
        footer: [],
      },
      modalWrapperStyle: {
        width: this.$props.minWidth,
        maxWidth: '100vw',
      },
    };
  },
  computed: {
    /**
     * @description Add custom classes to elements within our component
     * that we can use to override styles
     *
     * IMPORTANT!
     *
     * These are used for dirty/fast overrides and these should not be reused.
     * We should add a proper/good way to add overrides when we get the chance.
     *
     * this is not a method that should be used and you need to avoid this
     * if you can avoid it.
     *
     * Use the `$modal-variant` variable inside our globals to add separate
     * variants
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    segmentClass() {
      // Generate our classes
      return Object.fromEntries(
        Object.entries(this.defaultClasses).map(([key, entries]) => {
          // Retrieve our 'override' classes
          const Overrides = this.classes && this.classes[key] ? this.classes[key] : [];

          // Construct the updated object
          return [key, [...entries, ...Overrides].join(' ')];
        }),
      );
    },
    /**
     * Set our variant
     *
     * @returns {string}
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    modalWrapperVariant() {
      if (!this.$props.variant) return '';

      return `--${this.$props.variant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/mixins/cross';
  @import '@/assets/styles/partials/transitions';

  // Display our cross
  .sd-modal-close {
    @include cross($color: $color-white, $radius: 26px, $diagonal-width: 5px);

    position: absolute;
    right: var(--modal-padding-x);
  }

  .sd-modal {
    $parent: &;
    $class-sd-container: '-content';

    &__title {
      font-size: 2rem;
      font-weight: $font-weight-bold;
    }

    // Display our backdrop
    &__backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $zindex-modal;
      display: flex;
      align-items: stretch;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, .4);

      @include media-breakpoint-up(md) {
        align-items: center;
      }
    }

    // Display our modal container
    &#{$class-sd-container} {
      @function modal-inner-border($border-color, $border-width: 1px) {
        @return $border-color solid $border-width;
      }

      @mixin modal-base-padding($padding-x, $padding-y) {
        --modal-padding-x: #{$padding-x};
        --modal-padding-y: #{$padding-y};

        > :only-child {
          padding: $padding-x;
        }

        > :first-child:not(:only-child) {
          padding: $padding-x $padding-x $padding-y;
        }

        > :nth-child(1n+2):not(:last-child) {
          padding: $padding-y $padding-x;
        }

        > :last-child:not(:only-child) {
          padding: $padding-y $padding-x $padding-x;
        }
      }

      @mixin modal-content-base($modal-padding-inner, $modal-padding: $modal-padding-x) {
        display: flex;
        flex-direction: column;
        max-width: 100vw;
        margin: 0 auto;
        background-color: $modal-background;
        box-shadow: $modal-box-shadow;

        @include media-breakpoint-down(md) {
          min-width: 100%;
        }

        @include media-breakpoint-up(md) {
          max-height: calc(100% - 3rem);
          border-radius: $modal-border-radius;
        }

        @each $breakpoint, $modal-padding in $modal-padding {
          @if $breakpoint != '' {
            @include media-breakpoint-up($breakpoint) {
              @include modal-base-padding($modal-padding, $modal-padding-inner);
            }
          } @else {
            @include modal-base-padding($modal-padding, $modal-padding-inner);
          }
        }
      }

      @each $variant-name, $variant-value in $modal-variants {

        @if $variant-name != '' {
          &--#{$variant-name} {
            @include modal-content-base($variant-value);
          }
        } @else {
          @include modal-content-base($variant-value);
        }
      }

      // Disable margin for top level item within our header
      &__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      // Display our modal body / enable scrolling
      &__body {
        flex: 1 1 auto;
        overflow: auto;
      }
    }
  }
</style>
