/**
 * @file
 * @description State actions
 *
 * @author Thiago Fazzi (@ThiagoFazzi)
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
import { db } from '../config/Firebase';
import {
  SET_STATE_ITEM,
  RESET_STATE,
} from './helpers/mutationTypes';

export default {
  /**
   * Retrieve the 'active' break object
   *
   * @param commit
   * @param route
   * @returns {Promise<void>}
   *
   * @author Robin Eijsbouts (@RobinEijsbouts)
   * @since 1.0.3
   */
  setBreak({ commit }, route) {
    // Retrieve the metadata/parameters of our break
    const breakName = route.params.breakName || 'default';
    const category = route.params.category || 'default';

    // Retrieve the 'break' from the database
    db.collection('breaks')
      .where('name', '==', breakName)
      .where('category', '==', category)
      .limit(1)
      .onSnapshot((snapshot) => {
        // Only go further if we retrieved documents
        if (!snapshot.empty) {
          // Loop through our documents
          snapshot.docs.forEach((doc) => {
            try {
              // Set the hardcoded values because the keys are not available in the DB
              const streamTitle = ['red', 'orange', 'green', 'blue'];

              // Retrieve/parse the data
              const data = doc.data();

              // retrieve break id from a doc
              const breakId = doc.id;

              // Add the required data so we can render the streams
              const rooms = Object.values(data.rooms)
                .map((room, roomIndex) => ({
                  ...room,
                  id: Object.keys(data.rooms)[roomIndex],
                  title: streamTitle[roomIndex],
                }))
                .sort((prevItem, nextItem) => prevItem.order - nextItem.order);

              // Send our break id to the mutations
              commit(SET_STATE_ITEM, {
                name: 'breakId',
                data: breakId,
              });

              // Send our object to the mutations
              commit(SET_STATE_ITEM, {
                name: 'break',
                data: {
                  ...data,
                  rooms,
                },
              });
            } catch {
              // Reset our state to the default state if something goes wrong
              commit(RESET_STATE);
            }
          });
        }
      });
  },
};
