<template>
  <ul
    ref="dots"
    class="sd-carousel__dots">
    <li
        v-for="slide in slideCount"
        :key="slide">
      <button
        :class="{
          'sd-carousel__dot': slide - 1 !== activeSlide,
          'sd-carousel__dot--current': slide - 1 === activeSlide
        }"
        type="button"
        @click="$emit('active-slide', slide - 1)"></button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CarouselPagination',
  props: {
    slideCount: {
      type: Number,
      required: true,
      default: 0,
    },
    activeSlide: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/mixins/button';

.sd-carousel {
  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: calc(var(--modal-padding-y) / 2) 0 var(--modal-padding-y);
    list-style: none;

    > :not(:last-of-type) {
      margin-right: map-get($spacers, 2);
    }
  }

  &__dot {
    $parent: &;

    @include sd-button(
      transparent,
      rgba($carousel-dot-color, .2),
      transparent,
      rgba($carousel-dot-color, .3),
      transparent,
      rgba($carousel-dot-color, .4)
    );

    width: $carousel-dot-radius;
    height: $carousel-dot-radius;
    padding: 0;
    margin: 0;
    cursor: pointer;
    border: none;
    border-radius: 50%;

    &:hover,
    &:focus,
    &:active,
    &--current {
      @extend #{$parent};

      background-color: $carousel-dot-color;
    }
  }
}
</style>
