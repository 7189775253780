/**
 * @file
 * @description Default properties for a stream
 * @author Thiago Fazzi
 */
export default {
  data() {
    return {
      StreamPlayer: '',
    };
  },
  computed: {
    player() {
      return this.StreamPlayer;
    },
  },
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    channel: {
      type: String,
    },
    streamType: {
      type: String,
    },
    id: {
      type: String,
    },
    quality: {
      type: Object,
    },
  },
};
