<template>
  <component
    :is="tag"
    :style="style"
    class="sd-container">
    <slot></slot>
  </component>
</template>

<script>
/**
 * @file
 * @description Display our container within the grid
 *
 * @author      Robin Eijsbouts (@RobinEijsbouts)
 * @since       1.0.3
 */
import { getCssVariable, cssVar } from '../mixins/WindowUtils';

export default {
  name: 'sdContainer',
  mixins: [getCssVariable],
  props: {
    /**
     * @description Customise the HTML tag that's being displayed in the DOM
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    tag: {
      type: String,
      default: 'div',
    },
    /**
     * @description Add some vertical spacing between
     * the header/footer and our page
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    offsetVertical: {
      type: Number,
      validator: (value) => cssVar(`spacer-${value}`),
    },
  },
  computed: {
    /**
     * @description Add some styling to our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    style() {
      return {
        paddingTop: this.offsetVertical ? this.cssVar(`spacer-${this.offsetVertical}`) : '',
        paddingBottom: this.offsetVertical ? this.cssVar(`spacer-${this.offsetVertical}`) : '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';

  .sd-container {
    box-sizing: border-box;
    padding: 0 $grid-gutter-width;
    margin: 0 auto;

    // Add our max-width's to the container
    @each $breakpoint-name, $breakpoint-value in $breakpoints {
      @if $breakpoint-value != 0 {
        @include media-breakpoint-up($breakpoint-name) {
          max-width: $breakpoint-value;
        }
      }
    }
  }
</style>
