/**
 * @file
 * @description State store
 *
 * @author Thiago Fazzi (@ThiagoFazzi)
 */

import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import getDefaultState from './helpers/helpers';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: getDefaultState(),
  mutations,
  getters,
  actions,
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
