<template>
  <div class="sd-separator" />
</template>

<script>
export default {
  name: 'Separator',
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';

  .sd-separator {
    $separator-margin-x: .375rem;

    width: 1px;
    height: 100%;
    background-color: transparentize($color-white, .5);
    margin: {
      right: $separator-margin-x;
      left: $separator-margin-x;
    }

    @include media-breakpoint-up(md) {
      margin: {
        right: map-get($spacers, 2);
        left: map-get($spacers, 2);
      }
    }
  }
</style>
