<template>
  <youtube
    :video-id="channel"
    :width="width"
    :height="height"
    :ref="streamType"/>
</template>

<script>
import streamMixin from '../mixins/streamMixin';

export default {
  name: 'Youtube',
  mixins: [streamMixin],
  mounted() {
    this.streamPlayer = this.$refs[this.streamType] ? this.$refs[this.streamType].player : 'null';
    this.player.mute();
  },
  methods: {
    play() {
      this.player.playVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
    mute() {
      this.player.mute();
    },
    unMute() {
      this.player.unMute();
    },
  },
};
</script>
