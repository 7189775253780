<template>
  <Icon
    :src="mutedStreams.includes(id) ? 'mute' : 'speaker'"
    class="sd-volume-control"
    @click="handleClickEvent" />
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_MUTED_STREAMS } from '../store/helpers/mutationTypes';
import Icon from './Icon';

export default {
  name: 'VolumeControl',
  components: {
    Icon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'mutedStreams',
    ]),
  },
  methods: {
    /**
     * Toggle our 'mute' state of the stream
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    handleClickEvent() {
      if (this.id) {
        // Update/toggle the mute state of the stream
        this.$store.commit(UPDATE_MUTED_STREAMS, {
          id: this.id,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';

  .sd-volume-control {
    cursor: pointer;
    transition: $link-transition;

    &:hover,
    &:focus {
      filter: brightness(75%);
    }

    &:active {
      filter: brightness(70%);
    }
  }
</style>
