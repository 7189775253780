import eventBus from '../utils/EventBus';

/**
 * A helper function to emit events through the entire app
 *
 * @param varName
 * @returns {string}
 *
 * @since 1.0.0
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export default {
  methods: {
    /**
     * Emit global events
     *
     * @param name
     * @param param
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.0
     */
    emitGlobalEvent(name, param) {
      eventBus.$emit(name, param);
    },
  },
};
