<template>
  <div class='sd-carousel-wrapper'>
    <agile
      :infinite="false"
      :navButtons="false"
      :dots="false"
      :fade="true"
      ref="carousel">

      <section class="sd-slide">
        <h3 class="sd-slide__title">{{ $t('introModalSlideOneTitle') }}</h3>
        <p>{{ $t('introModalSlideOneContent') }}</p>
      </section>

      <section class="sd-slide">
        <h3 class="sd-slide__title">{{ $t('introModalSlideTwoTitle') }}</h3>
        <ol class="sd-list">
          <li class="sd-list__header">{{ $t('stepNumber', {number: 1}) }}</li>
          <li class="sd-list__item">{{ $t('introModalSlideTwoListitemOne') }}</li>

          <li class="sd-list__header">{{ $t('stepNumber', {number: 2}) }}</li>
          <li class="sd-list__item">{{ $t('introModalSlideTwoListitemTwo') }}</li>

          <li class="sd-list__header">{{ $t('stepNumber', {number: 3}) }}</li>
          <li class="sd-list__item">{{ $t('introModalSlideTwoListitemThree') }}</li>

          <li class="sd-list__header">{{ $t('stepNumber', {number: 4}) }}</li>
          <li class="sd-list__item">{{ $t('introModalSlideTwoListitemFour') }}</li>
        </ol>
      </section>

      <section class="sd-slide">
        <p>
          Silent Disco Break
          {{ $t('isInitiativeOf') }}
        </p>
        <p>
          <a
            class="sd-link"
            href="https://www.silentdiscoclub.nl/"
            alt="Silent Disco Club"
            target="_blank">Silent Disco Club</a>,
          <a
            class="sd-link"
            href="https://brthrs.nl/"
            alt="Brthrs Agency"
            target="_blank">Brthrs Agency</a> &
          <a
            class="sd-link"
            href="https://ovide.agency/"
            alt="Ovide agency"
            target="_blank">Ovide Agency</a>
        </p>
        <div class="sd-image-grid">
          <Icon
            src="logo-sdc"
            width="110px"></Icon>
          <Icon
            src="logo-brthrs"
            width="91px"></Icon>
          <Icon
            src="logo-ovide"
            width="98px"></Icon>
        </div>
      </section>

      <section class="sd-slide">
        <h3 class="sd-slide__title">{{ $t('introModalSlideFourTitle') }}</h3>
        <p v-html="$t('introModalSlideFourContent', {
          privacyLink: $t('privacyLink'),
          cookieLink: $t('cookieLink')
        })"></p>
      </section>
    </agile>
  </div>
</template>

<script>
/**
 * @file
 * @description Display our carousel /w info for the onboarding
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
import { VueAgile } from 'vue-agile';
import Icon from './Icon';

export default {
  name: 'Carousel',
  components: {
    agile: VueAgile,
    Icon,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // Reload our carousel styles every time the window gets resized
    window.addEventListener('resize', this.reloadCarousel);
  },
  mounted() {
    // Watch our carousel ref and pass it to our parent
    this.$watch((vm) => vm.$refs.carousel, (carouselRef) => {
      this.$emit('carousel-ref', carouselRef);
    }, {
      immediate: true,
    });
  },
  beforeDestroy() {
    // Remove our listener
    window.removeEventListener('resize', this.reloadCarousel);
  },
  methods: {
    /**
     * Reload the styles of our carousel
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    reloadCarousel() {
      if (this.$refs.carousel) {
        this.$refs.carousel.reload();
      }
    },
    /**
     * Update the active carousel slide
     *
     * @param activeSlide
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    setActiveSlide(activeSlide) {
      if (this.$refs.carousel) {
        this.$refs.carousel.goTo(activeSlide);
        this.$refs.carousel.restartAutoPlay();
      }
    },
  },
  watch: {
    /**
     * Reload the styles of our carousel & reset our modal when we open the modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    isVisible(state) {
      // Reload the styles of the modal
      this.reloadCarousel();

      if (state) {
        // Reset our carousel and place our user on the first slide
        this.setActiveSlide(0);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/mixins/button';
@import '@/assets/styles/components/carousel';

.sd-slide {
  $class-title: #{&}__title;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: {
    size: $font-size-xxs;
    weight: $font-weight-medium;
  };

  color: $color-white;

  @include media-breakpoint-up(md) {
    font-size: $font-size-lg;
  }

  > :nth-child(n):not(#{$class-title}) {
    margin: 0;
  }

  #{$class-title} {
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    margin: {
      top: 0;
      bottom: map-get($spacers, 3);
    }

    @include media-breakpoint-up(md) {
      font-size: $font-size-xxl;
    }
  }
}

.sd-image-grid {
  $image-spacer: map-get($spacers, 5);

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding-top: map-get($spacers, 5);
  user-select: none;

  @include media-breakpoint-down(md) {
    justify-content: space-around;

    > :nth-child(n) {
      margin: 0 $image-spacer;
    }
  }

  > :nth-child(n) {
    margin-right: $image-spacer;

    @include media-breakpoint-down(md) {
      margin-bottom: $image-spacer;
    }
  }
}

.sd-list {
  padding-left: 0;
  list-style: none;

  &__header {
    margin-bottom: map-get($spacers, 1);
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(md) {
      font-size: $font-size-lg;
    }
  }

  &__item:not(:last-child) {
    margin-bottom: map-get($spacers, 5);

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($spacers, 6);
    }
  }
}
</style>
