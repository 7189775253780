/**
 * @file
 * @description To use constants for Mutation Types
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */

export const SET_STATE_ITEM = 'setStateItem';
export const RESET_STATE = 'resetState';
export const UPDATE_MUTED_STREAMS = 'updateMutedStreams';
export const SET_ACTIVE_STREAM = 'setActiveStream';
