<template>
  <component
    :is="tag"
    :style="style">
    <slot></slot>
  </component>
</template>

<script>
/**
 * @file
 * @description Display some content
 *
 * @author      Robin Eijsbouts (@RobinEijsbouts)
 * @since       1.0.3
 */
import { getCssVariable, cssVar } from '../mixins/WindowUtils';

export default {
  name: 'sdText',
  mixins: [getCssVariable],
  props: {
    /**
     * @description Customise the HTML tag that's being displayed in the DOM
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    tag: {
      type: String,
      default: 'p',
    },
    /**
     * @description Add some spacing at the bottom of our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    fontSize: {
      type: String,
      default: 'base',
      validator: (value) => cssVar(`font-size-${value}`),
    },
    /**
     * @description Add some spacing at the bottom of our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    offsetBottom: {
      type: [Number, Boolean],
      default: 3,
      validator: (value) => {
        if (!Number.isInteger(value)) return true;

        return cssVar(`spacer-${value}`);
      },
    },
  },
  computed: {
    /**
     * @description Add some styling to our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    style() {
      return {
        marginBottom: this.cssVar(`spacer-${this.offsetBottom}`),
        fontSize: this.cssVar(`font-size-${this.fontSize}`),
      };
    },
  },
};
</script>
