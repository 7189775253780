<template>
  <section
    class="sd-video-overlay"
    :is-active="isActive"
    :is-desktop="isDesktop"
    :is-mouseover="isMouseover"
    :style="{
      '--border-color': color,
    }"
    @mouseover="isMouseover = true"
    @mouseleave="isMouseover = false"
    @click="!isActive ? $emit('active-stream', id) : null">

    <div class="sd-video-overlay__header">
      <Live />

      <div class="sd-video-overlay__wrap">
        <Icon
          v-show="isActive"
          src="chat"
          @click="emitGlobalEvent('open-chat')"/>

        <span
          class="sd-pill sd-pill--flex"
          :style="{ '--background-color': color }">

          <!-- Display our like count -->
          <Counter
            :count="likes"
            @click="$emit('like-stream', id)" />

          <!-- Display our separator -->
          <Separator />

          <!-- Display our listener count -->
          <Counter
            :count="listeners"
            icon="headphones"/>
        </span>
      </div>
    </div>

    <div class="sd-video-overlay__body">
      <Button
        :color="color"
        :text="streamTitle"
        v-show="!isActive"/>
    </div>

    <div
      v-show="isActive && !isDesktop"
      class="sd-video-overlay__footer">
      <span class="sd-video-overlay__content">
        <!-- Display our volume control -->
        <VolumeControl :id="id"/>

        <!-- Display our equalizer -->
        <Equalizer :barColor="color"/>
      </span>
    </div>
  </section>
</template>

<script>
import { isDesktop } from '../mixins/WindowUtils';
import GlobalEventHelper from '../mixins/GlobalEventHelper';
import Live from './Live';
import Button from './Button';
import Counter from './Counter';
import Separator from './Separator';
import VolumeControl from './VolumeControl';
import Equalizer from './Equalizer';
import Icon from './Icon';

export default {
  name: 'Overlay',
  mixins: [isDesktop, GlobalEventHelper],
  components: {
    Live,
    Button,
    Counter,
    Separator,
    VolumeControl,
    Equalizer,
    Icon,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
      default: 1,
    },
    color: {
      type: String,
      required: true,
      default: '#FF2652',
    },
    likes: {
      type: Number,
      required: true,
      default: 0,
    },
    listeners: {
      type: Number,
      required: true,
      default: 0,
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    streamTitle: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isMouseover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/mixins/utils';
  @import '@/assets/styles/components/pill';

  .sd-video-overlay {

    // Set our base styling for our overlay
    @mixin base-overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100%;
      border-radius: $border-radius-base;
      transition: $link-transition;

      &:hover > &::after,
      &:focus > &::after,
      &:active > &::after {
        opacity: 1;
      }
    }

    @mixin base-overlay-border($opacity: 1) {
      &::after {
        @include base-overlay;

        pointer-events: none;
        content: "";
        box-shadow: inset 0 0 0 $border-width-base var(--border-color);

        @if $opacity != 1 {
          opacity: $opacity;
        }
      }
    }

    $outer-padding: #{$spacer + $border-width-base};

    $class-header: #{&}__header;
    $class-body: #{&}__body;
    $class-footer: #{&}__footer;

    @include base-overlay;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;

    > :first-child {
      padding: {
        top: $outer-padding;
        right: $outer-padding;
        left: $outer-padding;
      }
    }

    > :last-child {
      padding: {
        right: $outer-padding;
        bottom: $outer-padding;
        left: $outer-padding;
      }
    }

    &[is-mouseover]:not([is-active]) {
      // Include our base border
      @include base-overlay-border(.5);

      cursor: pointer;
    }

    &:not([is-desktop])[is-active] {
      @include base-overlay-border();
    }

    &[is-desktop][is-active] {
      @include base-overlay-border();

      pointer-events: none;

      > #{$class-header} > :nth-child(n),
      > #{$class-body} > :nth-child(n),
      > #{$class-footer} > :nth-child(n) {
        pointer-events: all;
      }
    }

    &:not([is-active]):not([is-mouseover]),
    &:not([is-mouseover]):not([is-active]) {

      > #{$class-header} {
        filter: brightness(75%);
      }

      > :not(#{$class-header}) {
        opacity: 0;
      }
    }

    #{$class-header} {
      display: flex;
      justify-content: space-between;
      padding: $spacer $spacer 0;

      > :nth-child(n) {
        display: flex;
      }
    }

    #{$class-body} {
      @include base-overlay;

      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      > :nth-child(n) {
        pointer-events: all;
      }
    }

    #{$class-footer} {
      display: flex;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      align-items: flex-end;

      > :nth-child(n):not(:last-child) {
        margin-right: map-get($spacers, 3);
      }
    }

    &__wrap {
      display: flex;

      > :nth-child(n):not(:last-child) {
        margin-right: map-get($spacers, 3);
      }
    }
  }
</style>
