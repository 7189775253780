<template>
  <transition name="sd-fadeout">
    <section
      v-show="isVisible"
      class="sd-notice">
      <div class="sd-notice__header">
        <sd-header
          v-show="title"
          :type="6"
          font-weight="bold"
          :offset-bottom="false">
          {{ title }}
        </sd-header>

        <span
          class="sd-shape-cross"
          @click="toggleModal(false)" />
      </div>

      <div
        v-show="content"
        class="sd-notice__body">
        <sd-text
          :offset-bottom="false"
          font-size="xs">
          {{ content }}
        </sd-text>
      </div>
    </section>
  </transition>
</template>

<script>
import sdHeader from './Header';
import sdText from './Text';
import getLocalStorage from '../mixins/functions';
import baseToggle from '../mixins/BaseToggle';
import { isDesktop } from '../mixins/WindowUtils';

/**
 * @file
 * @description Modal layout as a container for all modals
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export default {
  name: 'Notice',
  mixins: [baseToggle, isDesktop, getLocalStorage],
  components: {
    sdHeader,
    sdText,
  },
  props: {
    /**
     * @description Define our 'notice' od
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    id: {
      type: String,
      default: '',
      required: true,
    },
    /**
     * @description Set the 'title' of the notice
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    title: {
      type: String,
      default: '',
      required: true,
    },
    /**
     * @description Set the 'content' of the notice
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    content: {
      type: String,
      default: '',
      required: true,
    },
  },
  watch: {
    isVisible(isVisibleNew) {
      const dismissedModals = this.getLocalStorage('dismissedModals');
      // Show modal only once
      if (isVisibleNew) {
        return localStorage.setItem('dismissedModals', JSON.stringify([...dismissedModals, this.id]));
      }
      return dismissedModals;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/mixins/cross';
  @import '@/assets/styles/partials/transitions';

  // Display our cross
  .sd-shape-cross {
    @include cross($color: $color-white, $radius: 20px, $diagonal-width: 4px);
  }

  .sd-notice {
    $notice-offset: map-get($spacers, 3);
    $notice-inset-x: map-get($spacers, 3);
    $notice-inset-y: map-get($spacers, 1);

    position: fixed;
    bottom: $notice-offset;
    left: $notice-offset;
    z-index: $zindex-notice;
    width: calc(100% - #{$notice-offset * 2});
    color: $color-white;
    text-align: left;
    background-color: $color-medium-gray;
    border-radius: $border-radius-xs;

    > :only-child {
      padding: $notice-inset-x;
    }

    > :first-child:not(:only-child) {
      padding: $notice-inset-x $notice-inset-x $notice-inset-y / 2;
    }

    > :nth-child(1n+2):not(:last-child) {
      padding: $notice-inset-y / 2 $notice-inset-x;
    }

    > :last-child:not(:only-child) {
      padding: $notice-inset-y / 2 $notice-inset-x $notice-inset-x;
    }

    &__header {
      display: flex;
      align-items: center;

      > :not([class^="sd-shape"]) {
        flex: 1;
      }
    }
  }
</style>
