<template>
  <sd-container :offset-vertical="6">
    <sd-row>
      <sd-col :cols="12">

        <!-- Display our main header -->
        <sd-header
          :type="2"
          font-weight="bold"
          :offset-bottom="isDesktop ? 7 : 5"
          text-align="center">{{ $t('faq').toUpperCase() }}</sd-header>

        <!-- Display our first question -->
        <sd-header
          :type="5"
          font-weight="medium">{{ $t('faqNoSound') }}</sd-header>

        <sd-text>{{ $t('faqNoSoundSubtitle') }}</sd-text>
        <List
          :items="lists.noSound"
          :offset-bottom="6"/>

        <!-- Display our second question -->
        <sd-header
          :type="5"
          font-weight="medium">{{ $t('faqBlackScreen') }}</sd-header>
        <List
          :items="lists.blackScreen"
          :offset-bottom="6"/>

        <!-- Display our third question -->
        <sd-header
          :type="5"
          font-weight="medium">{{ $t('faqWifiSpeaker') }}</sd-header>
        <List
          :items="lists.wifiSpeaker"
          :offset-bottom="6"/>

        <!-- Display our fourth question -->
        <sd-header
          :type="5"
          font-weight="medium">{{ $t('faqError') }}</sd-header>
        <List
          :items="lists.error"
          :offset-bottom="6"/>

        <!-- Display our fifth question -->
        <sd-header
          :type="5"
          font-weight="medium">{{ $t('faqBuffer') }}</sd-header>
        <List
          :items="lists.buffer"
          :offset-bottom="6"/>

        <!-- Display our fifth question -->
        <sd-header
          :type="5"
          font-weight="medium">{{ $t('faqBuffer') }}</sd-header>
        <List
          :items="lists.buffer"
          :offset-bottom="6"/>

        <!-- Display our sixth question -->
        <sd-header
          :type="5"
          font-weight="medium">{{ $t('faqRedirection') }}</sd-header>
        <List
          :items="lists.redirection"
          :offset-bottom="6"/>
      </sd-col>
    </sd-row>
  </sd-container>
</template>

<script>
import { isDesktop } from '../mixins/WindowUtils';
import sdHeader from '../components/Header';
import sdText from '../components/Text';
import List from '../components/List';
import sdContainer from '../components/Container';
import sdRow from '../components/Row';
import sdCol from '../components/Col';

/**
 * @file
 * @description Display our help view
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 * @since 1.0.1
 */
export default {
  name: 'Help',
  mixins: [isDesktop],
  components: {
    sdHeader,
    List,
    sdContainer,
    sdRow,
    sdCol,
    sdText,
  },
  data() {
    return {
      lists: {
        noSound: [
          this.$t('refreshPageTapDj'),
          this.$t('refreshPageWifiMode'),
          this.$t('checkVolume'),
          this.$t('isDeviceMuted'),
          this.$t('openInIncognito'),
        ],
        blackScreen: [
          this.$t('refreshPageTapDj'),
          this.$t('refreshEnableSpeakermode'),
          this.$t('openInIncognito'),
        ],
        wifiSpeaker: [
          this.$t('disconnectWifiSpeaker'),
          this.$t('refreshEnableSpeakermode'),
          this.$t('connectWifiSpeaker'),
        ],
        error: [
          this.$t('openInPrivate'),
          this.$t('emptyHistory'),
          this.$t('reloadPage'),
        ],
        buffer: [
          this.$t('refreshPageTapDj'),
          this.$t('refreshEnableSpeakermode'),
          this.$t('openInIncognito'),
        ],
        redirection: [
          this.$t('incognitoTryAgain'),
          this.$t('clearHistory'),
        ],
      },
    };
  },
};
</script>
