/**
 * @file
 * @description German translation table file
 * @author      Femke Buijs
 */
export default {
  live: 'Live',
  listenTo: 'anhören',
  isInitiativeOf: 'ist eine Initiative von',

  // General translations
  previous: 'Zurück',
  next: 'Weiter',
  stepNumber: 'Schritt {number}:',
  share: 'Teilen',
  privacy: 'Privacy',
  chat: 'Chat',
  help: 'Help',
  yes: 'ja',
  no: 'Nein',
  faq: 'faq',

  // Colors
  red: 'Rot',
  orange: 'Orange',
  green: 'Grün',
  blue: 'Blau',

  // Add to Homescreen pop-up modal
  addToHomeScreenModalTitle: 'Faster to Silent Disco Break?',
  addToHomeScreenModalDescription: 'Add Silent Disco Break to your homescreen',

  // Tooltips
  tooltipSpeakerMode: 'Enable this mode if you have slow internet and/or if you\'re using WiFi speakers.',

  // Introduction modal

  // Slide 1
  introModalSlideOneTitle: 'Was ist Silent Disco Break?',
  introModalSlideOneContent: 'Silent Disco Break ermöglicht es dir, eine echte Silent Disco in deinem eigenen Wohnzimmer oder während einer Arbeitspause zu erleben. Setze dir deine Kopfhörer auf und wähle den DJ aus, der deine Lieblingsmusik spielt.',

  // Slide 2
  introModalSlideTwoTitle: 'Wie funktioniert Silent Disco Break?',
  introModalSlideTwoListitemOne: 'Setze deine Kopfhörer auf oder verwende deine Ohrhöre',
  introModalSlideTwoListitemTwo: 'Klicke auf einen der DJs',
  introModalSlideTwoListitemThree: 'Zufrieden mit dem, was du hörst? Gib dem DJ einen 👍!',
  introModalSlideTwoListitemFour: 'Wechsle zwischen den DJs, wenn du dir etwas anderes anhören möchtest',

  // Slide 4
  introModalSlideFourTitle: 'Privacy & Cookies',
  introModalSlideFourContent: 'Durch Drücken der Taste stimmen Sie unserer zu <a href="{privacyLink}" class="sd-link" target="_blank">Datenschutz-Bestimmungen</a> und <a href="{cookieLink}" class="sd-link" target="_blank">Cookie-Richtlinie</a>.',

  // Introduction modal buttons
  introModalFinalButton: 'Silent Disco starten',

  // Header
  speakerMode: 'WiFi speaker {break}or bad WiFi?',

  // Footer
  contactUs: 'Kontakt',

  // Share modal/links
  shareOn: 'Teilen auf {platform}',
  shareText: 'Ich tanze gerade zu Silent Disco Break, möchte mich mir anschließen?',

  // Privacy & cookie links
  // These are in a translation string because the privacy/cookie link is different
  // for each language
  privacyLink: 'https://www.iubenda.com/privacy-policy/60585911',
  cookieLink: 'https://www.iubenda.com/privacy-policy/60585911/cookie-policy',

  // Copyright copy
  copyright: '©2020 Silent Disco Break',

  // Help page

  // Question #1
  faqNoSound: 'I don’t hear any sound',
  faqNoSoundSubtitle: 'If you don’t hear any sound, do the following:',

  refreshPageWifiMode: 'Refresh the page and enable the speaker / poor wifi mode (switch at the top)',
  checkVolume: 'Check your volume',
  isDeviceMuted: 'Make sure your device is not muted',

  // Question #2
  faqBlackScreen: 'The streams are not loading or I see a black screen',

  // Question #3
  faqWifiSpeaker: 'I want to listen via my WiFi speaker (s)',

  disconnectWifiSpeaker: 'Disconnect your device from your WiFi speaker (s)',
  connectWifiSpeaker: 'Connect your device from your WiFi speaker (s)',

  // Question #4
  faqError: 'I see a 3000 error',

  openInPrivate: 'Open the website in an incognito (private) window and try again',
  emptyHistory: 'Or empty your internet history and files and try again',
  reloadPage: 'Reload the page',

  // Question #5
  faqBuffer: 'The stream falters (buffer) a lot',

  // Question #6
  faqRedirection: 'The page does not load and I see “Too many redirections”',

  incognitoTryAgain: 'Open the website in an incognito (private) window and try again',
  clearHistory: 'Or empty your internet history and files and try again',

  // FAQ re-used sentences
  refreshPageTapDj: 'Refresh the page and tap in a dj',
  openInIncognito: 'Open the website in an incognito (private) window and try the steps above',
  refreshEnableSpeakermode: 'Refresh the page and enable speaker mode (switch on top)',
};
