/**
 * @file
 * @description State mutations
 *
 * @author Thiago Fazzi (@ThiagoFazzi)
 */
import Vue from 'vue';
import getDefaultState from './helpers/helpers';
import {
  SET_STATE_ITEM,
  RESET_STATE,
  UPDATE_MUTED_STREAMS,
  SET_ACTIVE_STREAM,
} from './helpers/mutationTypes';

export default {
  /**
   * @description Sets an item in the state
   * @author Femke Buijs
   */
  [SET_STATE_ITEM](state, payload) {
    const { name, data } = payload;
    Vue.set(state, name, data);
  },
  /**
   * @description Reset the state to the default state
   * @author Femke Buijs
   */
  [RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  /**
   * @description Add/remove a stream from the mutedStreams
   * @author Robin Eijsbouts (@RobinEijsbouts)
   */
  [UPDATE_MUTED_STREAMS](state, payload) {
    const { id, value } = payload;

    // Retrieve the updated list
    const updateMutedList = (streamId, isStreamMuted, currentList) => {
      // Remove our 'streamId' from our mutedStreams state
      if (currentList.includes(streamId) && !isStreamMuted) {
        const updatedList = currentList.filter((stream) => stream !== streamId);

        // Return our updated list or the defaultValue
        return updatedList || getDefaultState().mutedStreams;
      }

      // Add our 'streamId' from our mutedStreams state
      if (isStreamMuted && !currentList.includes(streamId)) {
        // Add the streamId to the muted list
        return [...currentList, streamId];
      }

      // Return our default state
      return currentList || getDefaultState().mutedStreams;
    };

    // Retrieve the 'newest' mute state and set or invert it
    const isNewState = ('value' in payload) ? value : !state.mutedStreams.includes(id);

    // Update our state
    Vue.set(state, 'mutedStreams', updateMutedList(id, isNewState, state.mutedStreams));
  },
  /**
   * @description Add current(selected) stream object to the activeStream
   * @author Thiago Fazzi (@ThiagoFazzi)
   */
  [SET_ACTIVE_STREAM](state, payload) {
    const { name, data } = payload;
    Vue.set(
      state,
      name,
      state.break.rooms.find((stream) => stream.id === data),
    );
  },
};
