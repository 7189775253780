<template>
  <modal
    v-show="isVisible"
    min-width="49.5rem"
    variant="info"
    :can-backdrop-dismiss="hasDismissed"
    @close="toggleModal(!isVisible)">

    <template slot="header">
      <Icon
        :width="isDesktop ? '11.25rem' : '8.5rem'"
        src="logo-silent-disco" />
      <span
        v-show="hasDismissed"
        class="sd-modal-close"
        @click="hasDismissed ? toggleModal(false) : null"/>
    </template>

    <!-- Display the body slot of first page -->
    <template slot="body">
      <Carousel
        :isVisible="isVisible"
        @carousel-ref="(carouselRef) => carousel = carouselRef" />
    </template>

    <!-- Display our footer -->
    <template
      v-if="carousel"
      slot="footer">
      <CarouselPagination
        v-show="!isBreakpointSM"
        :slide-count="carousel.slidesCount"
        :active-slide="carousel.currentSlide"
        :style="{
          'margin-bottom': '3rem',
        }"
        @active-slide="setActiveSlide"/>

      <section class="sd-carousel-footer">
        <span>
          <button
            v-show="carousel.canGoToPrev"
            class="sd-carousel-nav-btn--prev"
            @click="carousel.goToPrev()">{{ $t('previous') }}</button>
        </span>

        <CarouselPagination
          v-show="isBreakpointSM"
          :slide-count="carousel.slidesCount"
          :active-slide="carousel.currentSlide"
          @active-slide="setActiveSlide"/>

        <span>
          <button
            v-if="carousel.canGoToNext"
            class="sd-carousel-nav-btn--next"
            @click="carousel.goToNext()">{{ $t('next') }}</button>
          <button
            v-else
            class="sd-carousel-nav-btn--next"
            @click="toggleModal(false)">{{ $t('introModalFinalButton') }}</button>
        </span>
      </section>
    </template>
  </modal>
</template>

<script>
/**
 * @file
 * @description Display our info modal
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
import {
  Dimensions, getCssVariable, isDesktop, isIos, openedFromHomeScreen,
} from '../mixins/WindowUtils';
import getLocalStorage from '../mixins/functions';
import Modal from '../components/Modal';
import baseToggle from '../mixins/BaseToggle';
import Carousel from '../components/Carousel';
import CarouselPagination from '../components/CarouselPagination';
import Icon from '../components/Icon';
import GlobalEventHelper from '../mixins/GlobalEventHelper';

export default {
  name: 'InfoModal',
  mixins: [baseToggle, Dimensions, getCssVariable, isDesktop, GlobalEventHelper, getLocalStorage,
    isIos, openedFromHomeScreen],
  components: {
    Carousel,
    CarouselPagination,
    Modal,
    Icon,
  },
  computed: {
    /**
     * Add a custom breakpoint for our modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    isBreakpointSM() {
      return this.windowWidth > parseInt(this.cssVar('breakpoint-sm'), 10);
    },
  },
  data() {
    return {
      id: 'infoModal',
      hasDismissed: true,
      carousel: null,
      carouselSettings: null,
    };
  },
  created() {
    // Check if our user has dissmised our modal before
    this.hasDismissed = this.getLocalStorage('dismissedModals').includes(this.id);

    // Display our modal by default if the user did not dismiss the modal before
    if (!this.hasDismissed) {
      this.isVisible = true;
    }
  },
  watch: {
    /**
     * Manage our localstorage/registory to see if te modal has been
     * dismissed in the past
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @author Kristine Trona
     */
    isVisible(isVisibleNew) {
      // Retrieve the current value of our dismissedModals
      const dismissedModals = this.getLocalStorage('dismissedModals');

      if (!isVisibleNew && !this.hasDismissed) {
        // Update our local variable
        this.hasDismissed = true;

        // If the app is viewed from an android mobile device show addToHomeScreen modal
        if (!this.isDesktop && !this.isIos) {
          this.showAddToHomeScreenModal();
        }

        // For ios we have to check if the app is opened from a mobile browser and not
        // the homescreen and only then show the modal
        if (!this.isDesktop && this.isIos && !this.openedFromHomeScreen) {
          this.showAddToHomeScreenModal();
        }

        // Update our localStorage
        return localStorage.setItem(
          'dismissedModals',
          JSON.stringify([...dismissedModals, this.id]),
        );
      }

      return dismissedModals;
    },
    /**
     * Retrieve data from our carousel
     *
     * @param carousel
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    carousel(carousel) {
      if (carousel) {
        this.carouselSettings = carousel.getCurrentSettings();
      }
    },
  },
  methods: {
    /**
     * Update the active carousel slide
     *
     * @param activeSlide
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    setActiveSlide(activeSlide) {
      this.carousel.goTo(activeSlide);
      this.carousel.restartAutoPlay();
    },
    /**
     * @description Emits a global event that shows addToHomeScreen modal
     * @author Kristine Trona
     */
    showAddToHomeScreenModal() {
      setTimeout(() => this.emitGlobalEvent('toggle-visibility', 'addToHomeScreenModal'), 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/mixins/button';
@import '@/assets/styles/components/modal';
@import '@/assets/styles/components/carousel';
</style>
