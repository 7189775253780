<template>
  <div class="sd-live sd-pill--dark">
    <div class="sd-shape-circle" />
    <p class="sd-live__text">{{ $t('live').toUpperCase() }}</p>
  </div>
</template>

<script>
export default {
  name: 'Live',
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/mixins/utils';
  @import '@/assets/styles/components/pill';

  .sd-shape-circle {
    --radius: .625rem;

    width: var(--radius);
    height: var(--radius);
    margin-right: map-get($spacers, 1) / 2;
    background-color: $color-warning;
    border-radius: 50%;

    @include media-breakpoint-up(md) {
      --radius: 1.25rem;

      margin-right: map-get($spacers, 1);
    }
  }

  .sd-live {
    display: flex;
    align-items: center;

    &__text {
      margin: 0;
      font-size: $font-size-xxs;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: $font-size-lg;
      }
    }
  }
</style>
