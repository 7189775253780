/**
 * @file
 * @description Set our 'meta' tags
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 * @since 1.0.0
 */

export default {
  title: 'Silent Disco Break — Online Silent Disco at home',
  meta: [
    {
      name: 'description',
      content: 'Silent Disco Break allows you to experience a real online Silent Disco in the comfort of your own living room, or during your work break. Put your headphones on and choose the DJ that plays your favourite tunes.',
    },
    {
      itemprop: 'name',
      content: 'Silent Disco Break — Online Silent Disco at home',
    },
    {
      itemprop: 'description',
      content: 'Silent Disco Break allows you to experience a real online Silent Disco in the comfort of your own living room, or during your work break. Put your headphones on and choose the DJ that plays your favourite tunes.',
    },
    {
      itemprop: 'image',
      content: `${process.env.VUE_APP_BASE_URL}assets/img/silent-disco-break.jpg`,
    },
    {
      property: 'og:url',
      content: process.env.VUE_APP_BASE_URL,
    },
    {
      property: 'og:title',
      content: 'Experience a Silent Disco in your living room',
    },
    {
      property: 'og:description',
      content: 'Silent Disco Break allows you to experience a real online Silent Disco in the comfort of your own living room, or during your work break. Put your headphones on and choose the DJ that plays your favourite tunes.',
    },
    {
      property: 'og:image',
      content: `${process.env.VUE_APP_BASE_URL}assets/img/silent-disco-break.jpg`,
    },
    {
      property: 'og:site_name',
      content: 'Silent Disco Break — Online Silent Disco at home',
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:title',
      content: 'Experience a Silent Disco in your living room',
    },
    {
      name: 'twitter:description',
      content: 'Silent Disco Break allows you to experience a real online Silent Disco in the comfort of your own living room, or during your work break. Put your headphones on and choose the DJ that plays your favourite tunes.',
    },
    {
      name: 'twitter:image',
      content: `${process.env.VUE_APP_BASE_URL}assets/img/silent-disco-break.jpg`,
    },
  ],
};
