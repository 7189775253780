<template>
  <img
    :src="require(`@/assets/img/${src}.${extension}`)"
    :style="dimensions"
    :class="{
      'sd-icon--click': hasClick,
    }"
    @click="$emit('click')"/>
</template>

<script>
/**
 * @file
 * @description Display a icon
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */

export default {
  name: 'Icon',
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    height: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
    width: {
      type: [Number, String],
      required: false,
      default: '24px',
    },
    extension: {
      type: String,
      default: 'svg',
    },
  },
  computed: {
    /**
     * @description test if we have a event listener
     *
     * @author Robin Eijsbouts (@RobinEijsbouts
     * @since 1.0.3
     *
     * @returns Boolean
     */
    hasClick() {
      return Object.keys(this.$listeners).includes('click');
    },
    /**
     * Set the width/height of our icon
     *
     * @author Robin Eijsbouts (@RobinEijsbouts
     * @since 1.0.3
     *
     * @returns {{
     *   width: (default.props.width|{default, type, required}),
     *   height: (default.props.height|{default, type, required})
     * }}
     */
    dimensions() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';

  .sd-icon--click {
    cursor: pointer;
    transition: $link-transition;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      filter: brightness(90%);
    }

    &:not([disabled]):active {
      filter: brightness(85%);
    }
  }
</style>
