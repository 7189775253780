/**
 * @file
 * @description Italian translation table file
 * @author      Femke Buijs
 */
export default {
  live: 'Dal vivo',
  listenTo: 'Ascolta',
  isInitiativeOf: 'è un iniziativa di',

  // General translations
  previous: 'Precedente',
  next: 'Successivo',
  stepNumber: 'Passo {number}:',
  share: 'Condivisione',
  privacy: 'Privacy',
  chat: 'Chat',
  help: 'Help',
  yes: 'si',
  no: 'no',
  faq: 'faq',

  // Colors
  red: 'Rosso',
  orange: 'Orange',
  green: 'Verde',
  blue: 'Blu',

  // Add to Homescreen pop-up modal
  addToHomeScreenModalTitle: 'Faster to Silent Disco Break?',
  addToHomeScreenModalDescription: 'Add Silent Disco Break to your homescreen',

  // Tooltips
  tooltipSpeakerMode: 'Enable this mode if you have slow internet and/or if you\'re using WiFi speakers.',

  // Introduction modal

  // Slide 1
  introModalSlideOneTitle: 'Cos\'è Silent Disco Break',
  introModalSlideOneContent: 'Silent Disco Break ti permette di provare la vera esperienza della Silent Disco nella comodità del tuo salotto, o mentre sei in pausa al lavoro. Indossa le tue cuffie e scegli il DJ che suona la tua musica preferita.',

  // Slide 2
  introModalSlideTwoTitle: 'Come funziona Silent Disco Break ?',
  introModalSlideTwoListitemOne: 'Indossa le tue cuffie o gli auricolari',
  introModalSlideTwoListitemTwo: 'Clicca su uno dei DJ',
  introModalSlideTwoListitemThree: 'Sei felice di ciò che stai ascoltando? Metti un 👍 al DJ!',
  introModalSlideTwoListitemFour: 'Passa da un DJ all altro quando vuoi per ascoltare qualcosa di diverso',

  // Slide 4
  introModalSlideFourTitle: 'Privacy & Cookies',
  introModalSlideFourContent: 'Premendo il pulsante, accetti il ​​nostro <a href="{privacyLink}" class="sd-link" target="_blank">Politica sulla riservatezza</a> e <a href="{cookieLink}" class="sd-link" target="_blank">Gestione dei Cookie</a>.',

  // Introduction modal buttons
  introModalFinalButton: 'Inizia Silent Disco',

  // Header
  speakerMode: 'WiFi speaker {break}or bad WiFi?',

  // Footer
  contactUs: 'Contattaci',

  // Share modal/links
  shareOn: 'Condividi su {platform}',
  shareText: 'Sto ballando al Silent Disco Break in questo momento, vuoi unirti a me?',

  // Privacy & cookie links
  // These are in a translation string because the privacy/cookie link is different
  // for each language
  privacyLink: 'https://www.iubenda.com/privacy-policy/27844535',
  cookieLink: 'https://www.iubenda.com/privacy-policy/27844535/cookie-policy',

  // Help page

  // Question #1
  faqNoSound: 'I don’t hear any sound',
  faqNoSoundSubtitle: 'If you don’t hear any sound, do the following:',

  refreshPageWifiMode: 'Refresh the page and enable the speaker / poor wifi mode (switch at the top)',
  checkVolume: 'Check your volume',
  isDeviceMuted: 'Make sure your device is not muted',

  // Question #2
  faqBlackScreen: 'The streams are not loading or I see a black screen',

  // Question #3
  faqWifiSpeaker: 'I want to listen via my WiFi speaker (s)',

  disconnectWifiSpeaker: 'Disconnect your device from your WiFi speaker (s)',
  connectWifiSpeaker: 'Connect your device from your WiFi speaker (s)',

  // Question #4
  faqError: 'I see a 3000 error',

  openInPrivate: 'Open the website in an incognito (private) window and try again',
  emptyHistory: 'Or empty your internet history and files and try again',
  reloadPage: 'Reload the page',

  // Question #5
  faqBuffer: 'The stream falters (buffer) a lot',

  // Question #6
  faqRedirection: 'The page does not load and I see “Too many redirections”',

  incognitoTryAgain: 'Open the website in an incognito (private) window and try again',
  clearHistory: 'Or empty your internet history and files and try again',

  // FAQ re-used sentences
  refreshPageTapDj: 'Refresh the page and tap in a dj',
  openInIncognito: 'Open the website in an incognito (private) window and try the steps above',
  refreshEnableSpeakermode: 'Refresh the page and enable speaker mode (switch on top)',
};
