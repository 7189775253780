/**
 * Retrieve a css variable from our HTML DOM
 *
 * @param varName
 * @returns {string|null}
 *
 * @since 1.0.0
 * @autor Robin Eijsbouts (@RobinEijsbouts)
 */
export const cssVar = (varName) => {
  if (varName) {
    return getComputedStyle(document.documentElement).getPropertyValue(`--${varName}`);
  }

  return null;
};

/**
 * @description Retrieve our breakpoints
 *
 * @returns {string[]|Array}
 *
 * @since 1.0.3
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export const getBreakpoints = () => {
  // Retrieve our breakpoints based off our css variables
  const breakpoints = cssVar('breakpoints');

  // Return our default value
  if (!breakpoints) return [];

  // Convert our scss list to a array
  return breakpoints.split(',').map((breakpoint) => breakpoint.replace(/[^a-zA-Z0-9]/g, ''));
};

/**
 * Retrieve a css variable through js
 *
 * @param varName
 * @returns {string}
 *
 * @since 1.0.0
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export const getCssVariable = {
  methods: {
    cssVar,
  },
};

/**
 * Retrieve the dimensions of our window & save it as a variable
 *
 * @type {{data(): *, created(): void, methods: {updateDimensions(*): void}, beforeDestroy(): void}}
 *
 * @since 1.0.0
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export const Dimensions = {
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
    };
  },
  created() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDimensions);
  },
  methods: {
    /**
     * Update our dimensions
     *
     * @param e
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    updateDimensions(e) {
      this.windowWidth = parseInt(e ? e.target.innerWidth : window.innerWidth, 10);
      this.windowHeight = parseInt(e ? e.target.innerHeight : window.innerHeight, 10);
    },
  },
};

/**
 * Check if we have a mobile resolution
 *
 * @type {{mixins: [*, *], computed: {isDesktop(): *}}}
 *
 * @since 1.0.0
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
export const isDesktop = {
  mixins: [Dimensions, getCssVariable],
  computed: {
    isDesktop() {
      return this.windowWidth > parseInt(this.cssVar('breakpoint-md'), 10);
    },
  },
};

/**
 * Checks if user has opened the app on an ios device
 * @author Kristine Trona
 */
export const isIos = {
  computed: {
    isIos() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad/.test(userAgent);
    },
  },
};

/**
 * Checks if app is opened from the homescreen (only works on ios)
 * @author Kristine Trona
 */
export const openedFromHomeScreen = {
  computed: {
    openedFromHomeScreen() {
      return window.navigator.standalone;
    },
  },
};
