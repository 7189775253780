<template>
  <component
    :is="tag"
    :class="classes">
    <slot></slot>
  </component>
</template>

<script>
/**
 * @file
 * @description Display our column within the grid
 * @author      Robin Eijsbouts (@RobinEijsbouts)
 * @since       1.0.3
 */
import { cssVar, getBreakpoints } from '../mixins/WindowUtils';

// Set our prop object which is used to set the column
const colsDefaultProp = {
  type: Number,
  validator: (value) => (value >= 1 && value <= parseInt(cssVar('grid-columns'), 10)),
};

/**
 * @description Retrieve our breakpoints and create 'dynamic' props
 * based off the existing breakpoints
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 * @since 1.0.3
 */
const getBreakpointsProps = () => {
  // Retrieve our breakpoints
  const breakpoints = getBreakpoints();

  // Create our props object
  return Object.assign(...breakpoints.map((key) => ({
    [key]: colsDefaultProp,
  })));
};

export default {
  name: 'sdCol',
  props: {
    /**
     * @description Customise the HTML tag that's being displayed in the DOM
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    tag: {
      type: String,
      default: 'div',
    },
    /**
     * @description Add our default prop which defines the selected column
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    cols: {
      ...colsDefaultProp,
      required: true,
    },
    ...getBreakpointsProps(),
  },
  computed: {
    /**
     * @description Create/set the classes that our col has to use
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    classes() {
      // Retrieve our breakpoints based off our css variables and add our default
      // 'cols' prop
      const optionsArray = ['cols', ...getBreakpoints()];

      /**
       * @description Retrieve the classname for the selected col/breakpoint
       *
       * @param col
       * @param breakpoint
       * @param prefix
       * @returns {string}
       *
       * @since 1.0.3
       * @author Robin Eijsbouts (@RobinEijsbouts0
       */
      const getColClass = (col, breakpoint = null, prefix = 'sd-col') => {
        // Retrieve the 'parts' of our class
        const colClass = col ? `-${col}` : '';
        const breakpointClass = (breakpoint && getBreakpoints().includes(breakpoint)) ? `--${breakpoint}` : '';

        // Combine the parts, thus creating our target class
        return prefix + colClass + breakpointClass;
      };

      // Create our props object
      return Object.assign(...optionsArray.map((key) => {
        // Retrieve our classname and check if we entered the prop
        const hasProp = this[key];
        const colClass = getColClass(this[key], key);

        // Return our updated value
        return {
          [colClass]: hasProp,
        };
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';

  // Mixin to create a base column to prevent code duplication
  @mixin base-col() {
    box-sizing: border-box;

    @include media-breakpoint-down(md) {
      padding: {
        right: $grid-gutter-width / 4;
        left: $grid-gutter-width / 4;
      };
    }

    @include media-breakpoint-up(md) {
      padding: {
        right: $grid-gutter-width / 2;
        left: $grid-gutter-width / 2;
      };
    }

    @content;
  }

  .sd-col {
    // Create our base classes
    @for $i from 1 through $grid-columns {
      &-#{$i} {
        @include base-col {
          width: #{100% * ($i / 12)};
        }
      }
    }

    // Create our cols with breakpoints
    @for $i from 1 through $grid-columns {
      @each $breakpoint-name in map-keys($breakpoints) {
        // Set the 'default' value of our prefix
        $breakpoint-prefix: "";

        // Update our prefix
        @if $breakpoint-name != "" {
          $breakpoint-prefix: #{"--" +$breakpoint-name};
        }

        // Add our class
        &-#{$i}#{$breakpoint-prefix} {
          @include media-breakpoint-up($breakpoint-name) {
            @include base-col {
              width: #{100% * ($i / 12)};
            }
          }
        }
      }
    }
  }
</style>
