<template>
  <modal
    v-show="isVisible"
    :title="$t('share')"
    min-width="32rem"
    @close="toggleModal(!isVisible)">

    <!-- Display the body slot -->
    <template slot="body">
      <section class="sd-social-btn-group">
        <a
          class="sd-social-btn--twitter"
          :href="`https://twitter.com/intent/tweet?text=${encodeString(shareText + ' - '  + baseUrl)}`"
          alt="twitter"
          target="_blank">
          <Icon src="twitter"/>
          <span>{{ $t('shareOn', {platform: 'Twitter'}) }}</span>
        </a>

        <a
          class="sd-social-btn--facebook"
          :href="`https://www.facebook.com/sharer/sharer.php?u=${baseUrl}`"
          alt="facebook"
          target="_blank">
          <Icon src="facebook"/>
          <span>{{ $t('shareOn', {platform: 'Facebook'}) }}</span>
        </a>

        <a
          class="sd-social-btn--whatsapp"
          :href="`https://wa.me/?text=${encodeString(shareText + ' - '  + baseUrl)}`"
          alt="facebook"
          target="_blank">
          <Icon src="whatsapp"/>
          <span>{{ $t('shareOn', {platform: 'Whatsapp'}) }}</span>
        </a>
      </section>
    </template>
  </modal>
</template>

<script>
/**
 * @file
 * @description Display our share modal
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
import Modal from '../components/Modal';
import baseToggle from '../mixins/BaseToggle';
import Icon from '../components/Icon';

export default {
  name: 'ShareModal',
  mixins: [baseToggle],
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      id: 'shareModal',
      shareText: this.$t('shareText'),
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    /**
     * Encode text to add support for all browsers
     *
     * @param text
     * @returns {any}
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts0
     */
    encodeString(text) {
      return text ? encodeURI(text) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/components/modal';
@import '@/assets/styles/components/pill';

.sd-modal-title {
  display: inline-flex;
  margin: 0;
}

.sd-social {
  &-btn-group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-btn {
    $parent: &;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-semibold;
    color: $color-white;
    text-decoration: none;
    transition: $link-transition;

    &:hover,
    &:focus {
      filter: brightness(75%);
    }

    &:active {
      filter: brightness(70%);
    }

    > :nth-child(n):not(:last-child) {
      margin-right: map-get($spacers, 1);
    }

    &:not(:last-of-type) {
      margin-bottom: map-get($spacers, 5);
    }

    @each $color-name, $color-value in $social-colors {
      &--#{$color-name} {
        @extend .sd-pill;
        @extend #{$parent};

        --background-color: #{$color-value};
      }
    }
  }
}
</style>
