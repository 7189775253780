<template>
  <header class="sd-header">
    <!-- Display our header -->

    <!-- Display our 'home' link/logo -->
    <router-link
      v-show="isDesktop || !$route.meta.showSpeakermode"
      :to="getCurrentBreakRoute()"
      tag="section"
      class="sd-menu__item sd-link--filter">
      <Icon
        src="logo-silent-disco"
        :width="isDesktop ? '134px' : '100px'"/>
    </router-link>

    <!-- Display our toggle for the wifi speaker -->
    <section
      v-show="$route.meta.showSpeakermode"
      class="sd-switch-wrapper">

      <!-- Display our speakermode toggle -->
      <p v-html="$t('speakerMode', { break: isDesktop ? '' : '<br />' })"/>

      <!-- Display our 'wifi speaker' switch -->
      <toggle-button
        class="sd-switch"
        color="#FF2652"
        :value="playOnlyCurrentStream"
        :sync="true"
        :width="70"
        :height="32"
        :margin="5"
        :font-size="14"
        :css-colors="true"
        :labels="{
          checked: $t('yes').toUpperCase(),
          unchecked: $t('no').toUpperCase()
        }"
        @change="toggleSpeakerMode"/>

        <!-- Display our tooltip -->
        <object
          v-tooltip="{
            content: $t('tooltipSpeakerMode'),
            class: 'sd-tooltip',
          }"
          class="sd-tooltip-action">
          <Icon
            src="info"
            width="16px"/>
        </object>
    </section>

    <!-- Display our nav section -->
    <ul class="sd-menu">
      <!-- Display our help action -->
      <router-link
        :to="{ name: 'help' }"
        tag="li"
        class="sd-menu__item">{{ $t('help') }}</router-link>

       <a
         v-show="isDesktop"
         class="sd-pill--instagram sd-pill--0-square"
         alt="Instagram"
         href="https://www.instagram.com/silentdiscobreak/"
         target="_blank">
         <Icon
           src="instagram"
           width="32px"/>
       </a>
     </ul>
   </header>
 </template>

<script>
/**
  * @file
  * @description Display our header
  *
  * @author Robin Eijsbouts (@RobinEijsbouts)
  * @since 1.0.1
  */
import { mapGetters } from 'vuex';
import { ToggleButton } from 'vue-js-toggle-button';
import Icon from '../../components/Icon';
import { isDesktop } from '../../mixins/WindowUtils';
import GlobalEventHelper from '../../mixins/GlobalEventHelper';

export default {
  name: 'Header',
  mixins: [isDesktop, GlobalEventHelper],
  components: {
    Icon,
    ToggleButton,
  },
  computed: {
    ...mapGetters([
      'break',
    ]),
  },
  data() {
    return {
      playOnlyCurrentStream: false,
      defaultRoute: {
        name: 'defaultBreak',
      },
    };
  },
  methods: {
    /**
      * @description Toggles the playOnlyCurrentStream state value and emits
      * global event informing Dashboard.vue to toggle speaker mode on/off
      */
    toggleSpeakerMode() {
      this.playOnlyCurrentStream = !this.playOnlyCurrentStream;
      this.emitGlobalEvent('toggleSpeakerMode', this.playOnlyCurrentStream);
    },
    /**
     * Return the route to the 'active' break
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    getCurrentBreakRoute() {
      // Return the defaultRoute
      if (!this.break || this.break.name === 'default') return this.defaultRoute;

      // Return the personalised route
      return ({
        name: 'personalBreak',
        params: {
          category: this.break.category,
          breakName: this.break.name,
        },
      });
    },
  },
};
</script>

 <style lang="scss" scoped>
   @import '@/assets/styles/imports/component';
   @import '@/assets/styles/mixins/utils';
   @import '@/assets/styles/mixins/button';
   @import '@/assets/styles/components/pill';
   @import '@/assets/styles/components/switch';

   .sd-switch-wrapper > :nth-child(n):not(:last-child) {
     margin-right: map-get($spacers, 2);
   }

   // Display our header
   .sd-header {
     position: sticky;
     top: 0;
     z-index: $zindex-nav;
     display: flex;
     flex: 0 0 auto;
     align-items: center;
     min-height: $nav-min-height;
     padding: $header-padding-mobile;
     font-size: $font-size-base;
     color: $color-white;
     background-color: $color-black;
     background-image: map-join($nav-bg-map);

     @include media-breakpoint-up(md) {
       padding: $header-padding;
       font-size: $font-size-lg;
     }

     // Align our items to the center of the div
     > :nth-child(n) {
       display: flex;
       align-items: center;
     }

     // Align our first item to the left side
     > :first-child {
       display: flex;
       flex: 1;
     }

     // Center our 'content' sections
     > :nth-child(n+2):not(:last-child) {
       display: flex;
       flex: 1 0 auto;

       @include media-breakpoint-up(md) {
         justify-content: center;
       }
     }

     // Align our last item at the right side
     > :last-child {
       display: flex;
       flex: 1;
       justify-content: flex-end;
     }
   }

   // Display our nav menu
   .sd-menu {
     $class-item: & + '__item';

     // Add spacing to non menu items
     > :nth-child(n):not(#{$class-item}) {
       margin: 0 $nav-padding-x-mobile / 2;
       transition: $link-transition;

       @include media-breakpoint-up(md) {
         margin: 0 $nav-padding-x / 2;
       }

       &:last-child {
         margin-right: 0;
       }

       &[disabled] {
         cursor: not-allowed;
         filter: brightness(35%);
       }

       &:not([disabled]):hover,
       &:not([disabled]):focus {
         filter: brightness(75%);
       }

       &:not([disabled]):active {
         filter: brightness(70%);
       }
     }

     // Display our menu item
     #{$class-item} {
       padding: 0 $nav-padding-x-mobile / 2;
       font-weight: $font-weight-bold;
       @include sd-link($color-white);

       @include media-breakpoint-up(md) {
         padding: 0 $nav-padding-x / 2;
       }
     }
   }
 </style>
