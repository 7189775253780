/**
 * @file
 * @description French translation table file
 * @author      Femke Buijs
 */
export default {
  live: 'Direct',
  listenTo: 'Écouter',
  isInitiativeOf: 'est une initiative de',

  // General translations
  previous: 'Précédent',
  next: 'Suivant',
  stepNumber: 'Étape {number} :',
  share: 'Partager',
  privacy: 'Privacy',
  chat: 'Chat',
  help: 'Help',
  yes: 'oui',
  no: 'non',
  faq: 'faq',

  // Colors
  red: 'Rouge',
  orange: 'Orange',
  green: 'Vert',
  blue: 'Bleu',

  // Add to Homescreen pop-up modal
  addToHomeScreenModalTitle: 'Faster to Silent Disco Break?',
  addToHomeScreenModalDescription: 'Add Silent Disco Break to your homescreen',

  // Tooltips
  tooltipSpeakerMode: 'Enable this mode if you have slow internet and/or if you\'re using WiFi speakers.',

  // Introduction modal

  // Slide 1
  introModalSlideOneTitle: 'Qu\'est-ce-que Silent Disco Break?',
  introModalSlideOneContent: 'Silent Disco Break vous permet de tranquillement danser en silence dans votre salon ou pendant votre pause. Mettez votre casque et choisissez le DJ qui passe vos morceaux préférés.',

  // Slide 2
  introModalSlideTwoTitle: 'Comment marche Silent Disco Break ?',
  introModalSlideTwoListitemOne: 'Mettez votre casque ou vos écouteurs',
  introModalSlideTwoListitemTwo: 'Cliquez sur l\'un des DJ',
  introModalSlideTwoListitemThree: 'Vous aimez ce que vous entendez ? Donnez un 👍 au DJ !',
  introModalSlideTwoListitemFour: 'Changez de DJ lorsque vous souhaitez écouter autre chose',

  // Slide 4
  introModalSlideFourTitle: 'Privacy & Cookies',
  introModalSlideFourContent: 'En appuyant sur le bouton, vous serez d\'accord avec nos <a href="{privacyLink}" class="sd-link" target="_blank">Politique de confidentialité</a> et <a href="{cookieLink}" class="sd-link" target="_blank">Politique de cookies</a>.',

  // Introduction modal buttons
  introModalFinalButton: 'Démarrer Silent Disco',

  // Header
  speakerMode: 'WiFi speaker {break}or bad WiFi?',

  // Footer
  contactUs: 'Contact',

  // Share modal/links
  shareOn: 'Partager sur {platform}',
  shareText: 'Je danse pour Silent Disco Break en ce moment, tu veux me rejoindre?',

  // Privacy & cookie links
  // These are in a translation string because the privacy/cookie link is different
  // for each language
  privacyLink: 'https://www.iubenda.com/privacy-policy/55783512',
  cookieLink: 'https://www.iubenda.com/privacy-policy/55783512/cookie-policy',

  // Copyright copy
  copyright: '©2020 Silent Disco Break',

  // Help page

  // Question #1
  faqNoSound: 'I don’t hear any sound',
  faqNoSoundSubtitle: 'If you don’t hear any sound, do the following:',

  refreshPageWifiMode: 'Refresh the page and enable the speaker / poor wifi mode (switch at the top)',
  checkVolume: 'Check your volume',
  isDeviceMuted: 'Make sure your device is not muted',

  // Question #2
  faqBlackScreen: 'The streams are not loading or I see a black screen',

  // Question #3
  faqWifiSpeaker: 'I want to listen via my WiFi speaker (s)',

  disconnectWifiSpeaker: 'Disconnect your device from your WiFi speaker (s)',
  connectWifiSpeaker: 'Connect your device from your WiFi speaker (s)',

  // Question #4
  faqError: 'I see a 3000 error',

  openInPrivate: 'Open the website in an incognito (private) window and try again',
  emptyHistory: 'Or empty your internet history and files and try again',
  reloadPage: 'Reload the page',

  // Question #5
  faqBuffer: 'The stream falters (buffer) a lot',

  // Question #6
  faqRedirection: 'The page does not load and I see “Too many redirections”',

  incognitoTryAgain: 'Open the website in an incognito (private) window and try again',
  clearHistory: 'Or empty your internet history and files and try again',

  // FAQ re-used sentences
  refreshPageTapDj: 'Refresh the page and tap in a dj',
  openInIncognito: 'Open the website in an incognito (private) window and try the steps above',
  refreshEnableSpeakermode: 'Refresh the page and enable speaker mode (switch on top)',
};
