import Vue from 'vue';
import VueYoutube from 'vue-youtube';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics';
import Tooltip from 'vue-directive-tooltip';

import App from './App';
import router from './router/index';
import store from './store';
import i18n from './assets/languages/i18n';
import MetaInfo from './config/MetaInfo';

Vue.use(VueAnalytics, {
  id: process.env.FIREBASE_ANALYTICS_ID,
});

Vue.use(Tooltip);
Vue.use(VueYoutube);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  metaInfo: MetaInfo,
  render: (h) => h(App),
}).$mount('#app');
