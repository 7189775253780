<template>
  <ul
    :style="style"
    class="sd-list">
    <li
      v-for="listItem in items"
      :key="listItem"
      class="sd-list__item">
      {{ listItem }}
    </li>
  </ul>
</template>

<script>
/**
 * @file
 * @description Display a list
 *
 * @author      Robin Eijsbouts (@RobinEijsbouts)
 * @since       1.0.3
 */
import { getCssVariable, cssVar } from '../mixins/WindowUtils';

export default {
  name: 'sdList',
  mixins: [getCssVariable],
  props: {
    /**
     * @description Set the data that we want to display
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * @description Add some spacing at the bottom of our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    offsetBottom: {
      type: Number,
      default: 5,
      validator: (value) => cssVar(`spacer-${value}`),
    },
  },
  computed: {
    /**
     * @description Add some styling to our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    style() {
      return {
        marginBottom: this.cssVar(`spacer-${this.offsetBottom}`),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';

.sd-list {
  padding-left: map-get($spacers, 3);
  text-align: left;
  list-style-type: disc;

  &__item {
    margin-bottom: map-get($spacers, 1);
  }
}
</style>
