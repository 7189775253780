import Vue from 'vue';
import Router from 'vue-router';

// Import our templates
import DashboardTemplate from '../layout/DashboardTemplate';
import PageTemplate from '../layout/PageTemplate';

// Import our views
import HelpView from '../views/Help';
import DashboardView from '../views/Dashboard';
import ChatView from '../components/Chat';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/',
      component: DashboardTemplate,
      children: [
        {
          path: '',
          name: 'defaultBreak',
          components: {
            default: DashboardView,
            aside: ChatView,
          },
          meta: {
            isBreakroom: true,
            showSpeakermode: true,
          },
        },
        {
          path: ':category/:breakName',
          name: 'personalBreak',
          components: {
            default: DashboardView,
            aside: ChatView,
          },
          meta: {
            isBreakroom: true,
            showSpeakermode: true,
          },
        },
      ],
    },
    {
      path: '/',
      component: PageTemplate,
      children: [
        {
          path: 'help',
          name: 'help',
          component: HelpView,
        },
      ],
    },
  ],
  mode: 'history',
});

export default router;
