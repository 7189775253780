<template>
  <modal
    v-show="isVisible"
    :title="activeStream('streamId') | capitalize"
    :classes="classOverrides"
    min-width="75%"
    @close="toggleModal(!isVisible)">

    <!-- Display the body slot -->
    <template slot="body">
      <iframe
        frameborder="0"
        scrolling="yes"
        :id="activeStream('streamId')"
        :src="`https://www.twitch.tv/embed/${activeStream('streamId')}/chat?darkpopout&parent=${getBaseUrl}`"/>
    </template>
  </modal>
</template>

<script>
/**
 * @file
 * @description Modal to display the twitch chat
 *
 * @since 1.0.3
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 * @author Thiago Fazzi (@ThiagoFazzi)
 */
import { mapGetters } from 'vuex';
import Modal from '../components/Modal';
import baseToggle from '../mixins/BaseToggle';
import { capitalize } from '../utils/Filters';

export default {
  name: 'ChatModal',
  mixins: [baseToggle],
  filters: {
    capitalize,
  },
  components: {
    Modal,
  },
  data() {
    return {
      id: 'chatModal',
      classOverrides: {
        wrapper: ['sd-chat-wrapper'],
        header: ['sd-chat-header'],
        body: ['sd-chat-body'],
      },
    };
  },
  computed: {
    ...mapGetters([
      'activeStream',
    ]),
    getBaseUrl() {
      return window.location.hostname;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/components/modal';

// Overwrite modal styles
//
// IMPORTANT!
//
// These are dirty/fast overrides and these should not be reused.
// We should add a proper/good way to add overrides when we get the chance.
//
// this is not a method that should be used and you need to avoid this
// if you can avoid it.
//
// @author Robin Eijsbouts (@RobinEijsbouts)
// @since 1.0.3
/deep/ .sd-chat {
  // Retrieve the base values
  $padding-x: map-get($modal-padding-x, '');
  $padding-y: map-get($modal-variants, '');

  // Overwrite some style on our modal wrapper
  &-wrapper {
    --modal-padding-x: #{$padding-x};
    --modal-padding-y: #{$padding-y};

    @include media-breakpoint-up(md) {
      height: 75%;
      max-height: 50rem;
    }
  }

  // Overwrite some style on our modal header
  &-header {
    // Note: Always avoid !important it's a sign that
    // there's something wrong in the structure or that
    // you are trying to overwrite something that is not
    // meant to be overwritten
    padding: $padding-y $padding-x !important;

    > .sd-modal__title {
      @include media-breakpoint-down(md) {
        font-size: $font-size-md;
      }
    }
  }

  // Overwrite some style on our modal body
  &-body {
    display: flex;
    flex-direction: column;
    // Note: Always avoid !important it's a sign that
    // there's something wrong in the structure or that
    // you are trying to overwrite something that is not
    // meant to be overwritten
    padding: 0 !important;
    overflow: hidden;
    // Note: The border-radius should be inherited from modal.vue. It should not
    // be added as override
    border-bottom-right-radius: $modal-border-radius;
    border-bottom-left-radius: $modal-border-radius;

    // Stretch the items within our flexbox
    > :nth-child(n) {
      flex: 1;
    }
  }
}
</style>
