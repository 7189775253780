<template>
  <object
      :style="{ '--bar-color': barColor }"
      class="sd-equalizer">
    <span
        v-for="n in 5"
        :key="n"></span>
  </object>
</template>

<script>
export default {
  name: 'Equilizer',
  props: {
    barColor: String,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';

  @mixin keyframe-base($animation-name, $keyframes) {
    @keyframes #{$animation-name} {
      @each $keyframe-timing, $keyframe-value in $keyframes {
        #{$keyframe-timing} {
          height: #{$keyframe-value};
        }
      }
    }
  }

  @mixin equalizer-bar($animination-name, $duration: 1s) {
    animation-name: animation-bar1;
    animation-duration: $duration;
    animation-iteration-count: infinite;
  }

  .sd-equalizer {
    display: flex;
    align-items: flex-end;
    height: 1.9rem;

    > :nth-child(n) {
      width: .25rem;
      height: 1.75rem;
      margin-right: .125rem;
      background-color: var(--bar-color);
      border-radius: .3em;
    }

    > :first-child {
      @include equalizer-bar(animation-bar1, 1.25s);
    }

    > :nth-child(2) {
      @include equalizer-bar(animation-bar2, 2.25s);
    }

    > :nth-child(3) {
      @include equalizer-bar(animation-bar3, 1.8s);
    }

    > :nth-child(4) {
      @include equalizer-bar(animation-bar4, 1.95s);
    }

    > :nth-child(5) {
      @include equalizer-bar(animation-bar5, 1.65s);
    }
  }
  @include keyframe-base(animation-bar1, (
    '0%': 5.6%,
    '25%': 100%,
    '50%': 53%,
    '70%': 71%,
    '100%': 20%,
  ));

  @include keyframe-base(animation-bar2, (
    '0%': 10.7%,
    '25%': 89.3%,
    '50%': 53.5%,
    '70%': 71.4%,
    '100%': 30%,
  ));

  @include keyframe-base(animation-bar3, (
    '0%': 7.1%,
    '25%': 100%,
    '50%': 53.7%,
    '70%': 71.4%,
    '100%': 40%,
  ));

  @include keyframe-base(animation-bar4, (
    '0%': 25%,
    '25%': 100%,
    '50%': 53.6%,
    '70%': 71%,
    '100%': 40%,
  ));

  @include keyframe-base(animation-bar5, (
    '0%': 17.9%,
    '25%': 100%,
    '50%': 53.6%,
    '70%': 71.4%,
    '100%': 45.4%,
  ));
</style>
