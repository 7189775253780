/**
 * @file
 * @description Filters
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */

/**
 * @description Capitalize the first letter of a string
 *
 * @param value
 * @returns {string}
 *
 * @author Robin Eijsbouts (@RobinEijsbots)
 * @since 1.0.3
 */
export const capitalize = (value) => {
  if (!value) return '';

  return value.toString().charAt(0).toUpperCase() + value.slice(1);
};

export { capitalize as default };
