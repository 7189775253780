<template>
  <a
    :style="{ backgroundColor: color }"
    type="button"
    class="sd-btn">
    <Icon src="speaker"/>
    <span>{{ $t('listenTo') }}</span>
  </a>
</template>

<script>
import Icon from './Icon';

export default {
  name: 'Button',
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      required: true,
      default: 'rood',
    },
    color: String,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/mixins/utils';

.sd-btn {

  // Set our box-shadow color map
  $box-shadow-color: (
    rgba($color-black, .1),
    0 0 0 0 rgba($color-black, .2),
    inset 0 -1px 1px 0 rgba($color-black, .1),
  );

  display: inline-flex;
  align-items: center;
  padding: map-get($spacers, 2) map-get($spacers, 3);
  color: $color-white;
  cursor: pointer;
  font: {
    size: $font-size-lg;
    weight: $font-weight-semibold;
  }

  border-radius: $border-radius-base;
  box-shadow: 0 1px 2px 0 map-join($box-shadow-color);
  transition: $link-transition;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &:hover,
  &:focus {
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(85%);
  }

  > :nth-child(n):not(:last-child) {
    margin-right: map-get($spacers, 1);
  }
}
</style>
