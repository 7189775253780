/**
 * @file
 * @description State getters
 *
 * @author Thiago Fazzi (@ThiagoFazzi)
 */
export default {
  /**
   * Return the streams within the current break
   *
   * @param state
   * @returns {null|rooms|{}}
   *
   * @author Robin Eijsbouts (@RobinEijsbouts)
   * @since 1.0.3
   */
  streams(state) {
    // Return nothing if we do not have the rooms
    if (!state.break || !state.break.rooms) return null;

    // Return our 'rooms' key
    return state.break.rooms;
  },
  /**
   * Return the current break
   *
   * @param state
   * @returns {null|rooms|{}}
   *
   * @author Robin Eijsbouts (@RobinEijsbouts)
   * @since 1.0.3
   */
  break(state) {
    // Return nothing if we do not have a break
    if (!state.break) return null;

    // Return our 'break'
    return state.break;
  },
  /**
   * Return our mutedStreams
   *
   * @param state
   * @returns {
   *  default.mutedStreams|(function(*))|default.computed.mutedStreams|default.watch.mutedStreams|[]
   * }
   *
   * @author Robin Eijsbouts (@RobinEijsbouts)
   * @since 1.0.3
   */
  mutedStreams: (state) => state.mutedStreams,
  /**
   * @description Return the streamId property from active stream
   *
   * @author Thiago Fazzi (@ThiagoFazzi)
   * @since 1.0.3
   */
  activeStream: (state) => (property) => state.activeStream[property],
  /**
   * @description Return the break id property from state
   *
   * @author Thiago Fazzi (@ThiagoFazzi)
   * @since 1.0.3-2
   */
  breakId: (state) => state.breakId,
  /**
  * @description Return the break chatService property from state
  *
  * @author Thiago Fazzi (@ThiagoFazzi)
  * @since 1.0.4
  */
  chatService(state) {
    if (!state.break) return null;

    // return our chatService
    return state.break.chatService;
  },
};
