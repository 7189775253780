<template>
  <section>
    <Header />
    <router-view class="sd-body" />
    <Footer/>
  </section>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';

export default {
  name: 'RouterBase',
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/mixins/template';

  .sd-body {
    @include base-template-body(column, flex-start);
  }
</style>
