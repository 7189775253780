/**
* Retrieve a value from our localstorage
*
* @param name
*/
const getLocalStorage = {
  methods: {
    getLocalStorage(name, defaultValue = []) {
      const dismissedModals = localStorage.getItem('dismissedModals') !== null ? localStorage.getItem('dismissedModals') : JSON.stringify(defaultValue);

      return JSON.parse(dismissedModals);
    },
  },
};

export default getLocalStorage;
