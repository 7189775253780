<template>
  <div
    :disabled="!hasClick"
    class="sd-counter"
    @click="$emit('click')">
    <Icon
      :src="icon"
      :width="iconWidth"/>
    <div class="sd-counter__count">{{ count }}</div>
  </div>
</template>

<script>
/**
 * @file
 * @description Create a simple reusable counter
 *
 * @author Robin Eijsbouts (@RobinEijsbouts)
 */
import Icon from './Icon';
import { isDesktop } from '../mixins/WindowUtils';

export default {
  name: 'Counter',
  mixins: [isDesktop],
  components: {
    Icon,
  },
  props: {
    count: {
      type: Number,
      required: true,
      default: 0,
    },
    icon: {
      type: String,
      default: 'likes',
    },
  },
  computed: {
    /**
     * Return our icon width
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     * @returns String
     */
    iconWidth() {
      return this.isDesktop ? '24px' : '18px';
    },
    /**
     * Check if we have a handler for the 'click' event
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     * @returns Boolean
     */
    hasClick() {
      return this.$listeners && this.$listeners.click;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/imports/component';

  .sd-counter {
    display: flex;
    align-items: center;
    user-select: none;
    transition: $link-transition;

    // Display our hover/focus/active state
    &:not([disabled]) {
      cursor: pointer;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      filter: brightness(90%);
    }

    &:not([disabled]):active {
      filter: brightness(85%);
    }

    // Add spacing between the items
    > :nth-child(n):not(:last-child) {
      margin-right: map-get($spacers, 1) / 2;

      @include media-breakpoint-up(md) {
        margin-right: map-get($spacers, 1);
      }
    }

    // Display our 'count' string
    &__count {
      color: $color-white;
      text-align: center;
      font: {
        size: $font-size-sm;
        weight: $font-weight-bold;
      }

      @include media-breakpoint-up(md) {
        font-size: $font-size-xl;
      }
    }
  }
</style>
