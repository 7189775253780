/**
 * @file
 * @description Dutch translation table file
 * @author      Femke Buijs
 */
export default {
  live: 'Live',
  listenTo: 'Luister',
  isInitiativeOf: 'is een initiatief van',

  // General translations
  previous: 'Vorige',
  next: 'Ga verder',
  stepNumber: 'Stap {number}:',
  share: 'Delen',
  privacy: 'Privacy',
  chat: 'Chat',
  help: 'Help',
  yes: 'ja',
  no: 'nee',
  faq: 'faq',

  // Colors
  red: 'Rood',
  orange: 'Oranje',
  green: 'Groen',
  blue: 'Blauw',

  // Add to Homescreen pop-up modal
  addToHomeScreenModalTitle: 'Sneller naar Silent Disco Break?',
  addToHomeScreenModalDescription: 'Voeg Silent Disco Break toe aan je beginscherm',

  // Tooltips
  tooltipSpeakerMode: 'Schakel deze modus in als je gebruik maakt van een WiFi speaker of als je internet langzaam is.',

  // Introduction modal

  // Slide 1
  introModalSlideOneTitle: 'Welkom bij Silent Disco Break!',
  introModalSlideOneContent: 'Op Silent Disco Break kan je de komende weken in je huiskamer of in je pauze een echte Silent Disco ervaren. Doe je oortjes in of je koptelefoon op en kies de DJ die jouw favoriete platen draait.',

  // Slide 2
  introModalSlideTwoTitle: 'Hoe werkt Silent Disco Break?',
  introModalSlideTwoListitemOne: 'Zet je koptelefoon op of doe je oortjes in. Luister je via speakers? Klik op "Speaker Mode" in de footer.',
  introModalSlideTwoListitemTwo: 'Klik op één van de DJ\'s.',
  introModalSlideTwoListitemThree: 'Blij met wat je luistert? Geef de DJ een 👍!',
  introModalSlideTwoListitemFour: 'Wissel tussen de DJ\'s op het moment dat je iets anders wilt luisteren.',

  // Slide 4
  introModalSlideFourTitle: 'Privacy & Cookies',
  introModalSlideFourContent: 'Door op de knop te drukken, ga je akkoord met onze <a href="{privacyLink}" class="sd-link" target="_blank">Privacybeleid</a> en <a href="{cookieLink}" class="sd-link" target="_blank">Cookiebeleid</a>.',

  // Introduction modal buttons
  introModalFinalButton: 'Start Silent Disco',

  // Header
  speakerMode: 'WiFi speaker {break}of slechte WiFi?',

  // Footer
  contactUs: 'Neem contact op',

  // Share modal/links
  shareOn: 'Deel via {platform}',
  shareText: 'Ik ben aan het dansen op Silent Disco Break. Doe je mee?',

  // Privacy & cookie links
  // These are in a translation string because the privacy/cookie link is different
  // for each language
  privacyLink: 'https://www.iubenda.com/privacy-policy/47482094',
  cookieLink: 'https://www.iubenda.com/privacy-policy/47482094/cookie-policy',

  // Copyright copy
  copyright: '©2020 Silent Disco Break',

  // Help page

  // Question #1
  faqNoSound: 'I don’t hear any sound',
  faqNoSoundSubtitle: 'If you don’t hear any sound, do the following:',

  refreshPageWifiMode: 'Refresh the page and enable the speaker / poor wifi mode (switch at the top)',
  checkVolume: 'Check your volume',
  isDeviceMuted: 'Make sure your device is not muted',

  // Question #2
  faqBlackScreen: 'The streams are not loading or I see a black screen',

  // Question #3
  faqWifiSpeaker: 'I want to listen via my WiFi speaker (s)',

  disconnectWifiSpeaker: 'Disconnect your device from your WiFi speaker (s)',
  connectWifiSpeaker: 'Connect your device from your WiFi speaker (s)',

  // Question #4
  faqError: 'I see a 3000 error',

  openInPrivate: 'Open the website in an incognito (private) window and try again',
  emptyHistory: 'Or empty your internet history and files and try again',
  reloadPage: 'Reload the page',

  // Question #5
  faqBuffer: 'The stream falters (buffer) a lot',

  // Question #6
  faqRedirection: 'The page does not load and I see “Too many redirections”',

  incognitoTryAgain: 'Open the website in an incognito (private) window and try again',
  clearHistory: 'Or empty your internet history and files and try again',

  // FAQ re-used sentences
  refreshPageTapDj: 'Refresh the page and tap in a dj',
  openInIncognito: 'Open the website in an incognito (private) window and try the steps above',
  refreshEnableSpeakermode: 'Refresh the page and enable speaker mode (switch on top)',
};
