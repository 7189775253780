<template>
  <component
    :is="tag"
    :style="style">
    <slot></slot>
  </component>
</template>

<script>
/**
 * @file
 * @description Display our header
 *
 * @author      Robin Eijsbouts (@RobinEijsbouts)
 * @since       1.0.3
 */
import { getCssVariable, cssVar } from '../mixins/WindowUtils';

export default {
  name: 'sdHeader',
  mixins: [getCssVariable],
  props: {
    /**
     * @description Set our html tag
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    type: {
      type: Number,
      default: 1,
      validator: (value) => (value >= 1 && value <= 6),
    },
    /**
     * @description Set the font-weight defined within css variables
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    fontWeight: {
      type: String,
      default: null,
      validator: (value) => cssVar(`font-weight-${value}`),
    },
    /**
     * @description Set the our text-align to prevent unintended behaviour
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    textAlign: {
      type: String,
      default: 'left',
      validator: (value) => {
        // Retrieve the 'valid' values
        const validValues = ['center', 'left', 'right', 'justify'];

        // Test if our entered value is included in our 'valid' values
        return validValues.includes(value);
      },
    },
    /**
     * @description Add some spacing at the bottom of our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    offsetBottom: {
      type: [Number, Boolean],
      default: 5,
      validator: (value) => {
        if (!Number.isInteger(value)) return true;

        return cssVar(`spacer-${value}`);
      },
    },
  },
  computed: {
    /**
     * Add a custom breakpoint for our modal
     *
     * @since 1.0.0
     * @author Robin Eijsbouts (@RobinEijsbouts)
     */
    tag() {
      return `h${this.type}`;
    },
    /**
     * @description Add some styling to our component
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    style() {
      return {
        fontSize: this.cssVar(`header-font-size-${this.type}`),
        fontWeight: this.cssVar(`font-weight-${this.fontWeight}`),
        marginBottom: this.cssVar(`spacer-${this.offsetBottom}`),
        textAlign: this.textAlign,
      };
    },
  },
};
</script>
