/**
 * @file
 * @description i18n setup for Vue JS
 * @author      Femke Buijs
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import nl from './nl';
import it from './it';
import fr from './fr';
import de from './de';
import es from './es';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    nl,
    it,
    fr,
    de,
    es,
  },
});

export default i18n;
