<template>
  <div>
    <Header />
    <section class="sd-body--dashboard">
      <router-view />
      <router-view
        name="aside"
        class="sd-aside"/>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';

export default {
  name: 'RouterBase',
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';
@import '@/assets/styles/mixins/template';

.sd {
  $class-aside: #{&}-aside;

  &-body--dashboard {
    display: flex;
    flex: 1 1 auto;

    > :nth-child(n) {
      &:not(#{$class-aside}) {
        @include base-template-body(row, center);
      }

      // Display our 'aside'
      &#{$class-aside} {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
