/**
 * @file
 * @description English translation table file
 * @author      Femke Buijs
 */
export default {
  live: 'Live',
  listenTo: 'Listen',
  isInitiativeOf: 'is an initiative of',

  // General translations
  previous: 'Previous',
  next: 'Next',
  stepNumber: 'Step {number}:',
  share: 'Share',
  privacy: 'Privacy',
  chat: 'Chat',
  help: 'Help',
  yes: 'yes',
  no: 'no',
  faq: 'faq',

  // Colors
  red: 'Red',
  orange: 'Orange',
  green: 'Green',
  blue: 'Blue',

  // Add to Homescreen pop-up modal
  addToHomeScreenModalTitle: 'Faster to Silent Disco Break?',
  addToHomeScreenModalDescription: 'Add Silent Disco Break to your homescreen',

  // Tooltips
  tooltipSpeakerMode: 'Enable this mode if you have slow internet and/or if you\'re using WiFi speakers.',

  // Introduction modal

  // Slide 1
  introModalSlideOneTitle: 'Welcome to Silent Disco Break!',
  introModalSlideOneContent: 'Silent Disco Break allows you to experience a real Silent Disco in the comfort of your own living room, or during your work break. Put your headphones on and choose the DJ that plays your favourite tunes.',

  // Slide 2
  introModalSlideTwoTitle: 'How does Silent Disco Break work?',
  introModalSlideTwoListitemOne: 'Put your headphones on or earphones in. Listening via speakers? Click on "Speaker mode" in the footer.',
  introModalSlideTwoListitemTwo: 'Click on one of the DJs.',
  introModalSlideTwoListitemThree: 'Happy with what you\'re hearing? Give the DJ a 👍!',
  introModalSlideTwoListitemFour: 'Switch between the DJs when you want to listen to something else.',

  // Slide 4
  introModalSlideFourTitle: 'Privacy & Cookies',
  introModalSlideFourContent: 'By pressing the button, you’ll agree with our <a href="{privacyLink}" class="sd-link" target="_blank">Privacy policy</a> and <a href="{cookieLink}" class="sd-link" target="_blank">Cookie policy</a>.',

  // Introduction modal buttons
  introModalFinalButton: 'Start Silent Disco',

  // Header
  speakerMode: 'WiFi speaker {break}or bad WiFi?',

  // Footer
  contactUs: 'Contact',

  // Share modal/links
  shareOn: 'Share on {platform}',
  shareText: 'I’m dancing to Silent Disco Break right now, want to join me?',

  // Privacy & cookie links
  // These are in a translation string because the privacy/cookie link is different
  // for each language
  privacyLink: 'https://www.iubenda.com/privacy-policy/91058406',
  cookieLink: 'https://www.iubenda.com/privacy-policy/91058406/cookie-policy',

  // Copyright copy
  copyright: '©2020 Silent Disco Break',

  // Help page

  // Question #1
  faqNoSound: 'I don’t hear any sound',
  faqNoSoundSubtitle: 'If you don’t hear any sound, do the following:',

  refreshPageWifiMode: 'Refresh the page and enable the speaker / poor wifi mode (switch at the top)',
  checkVolume: 'Check your volume',
  isDeviceMuted: 'Make sure your device is not muted',

  // Question #2
  faqBlackScreen: 'The streams are not loading or I see a black screen',

  // Question #3
  faqWifiSpeaker: 'I want to listen via my WiFi speaker (s)',

  disconnectWifiSpeaker: 'Disconnect your device from your WiFi speaker (s)',
  connectWifiSpeaker: 'Connect your device from your WiFi speaker (s)',

  // Question #4
  faqError: 'I see a 3000 error',

  openInPrivate: 'Open the website in an incognito (private) window and try again',
  emptyHistory: 'Or empty your internet history and files and try again',
  reloadPage: 'Reload the page',

  // Question #5
  faqBuffer: 'The stream falters (buffer) a lot',

  // Question #6
  faqRedirection: 'The page does not load and I see “Too many redirections”',

  incognitoTryAgain: 'Open the website in an incognito (private) window and try again',
  clearHistory: 'Or empty your internet history and files and try again',

  // FAQ re-used sentences
  refreshPageTapDj: 'Refresh the page and tap in a dj',
  openInIncognito: 'Open the website in an incognito (private) window and try the steps above',
  refreshEnableSpeakermode: 'Refresh the page and enable speaker mode (switch on top)',
};
