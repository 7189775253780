<template>
  <component
    :is="tag"
    class="sd-row">
    <slot></slot>
  </component>
</template>

<script>
/**
 * @file
 * @description Display our row used in our grid
 *
 * @author      Robin Eijsbouts (@RobinEijsbouts)
 * @since       1.0.3
 */
export default {
  name: 'sdRow',
  props: {
    /**
     * @description Customise the HTML tag that's being displayed in the DOM
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss" scoped>
  .sd-row {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
