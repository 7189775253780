<template>
  <section class="sd-dashboard">
    <div
      v-show="streams.length > 0"
      v-for="(stream, index) in streams"
      :key="stream.id"
      class="sd-video">

      <div class="sd-video__iframe">
        <Stream
          :is-active="activeStream === stream.id"
          :color="setColor(index)"
          :channel="stream.streamId"
          :source="stream.source"
          :id="stream.id"
          :ref="stream.id"
          :quality="{
            mobile: stream.mobileQuality,
            desktop: stream.desktopQuality,
          }"
        />
      </div>

      <Overlay
        :id="stream.id"
        :color="setColor(index)"
        :likes="stream.likes"
        :listeners="stream.listeners + (activeStream === stream.id)"
        :is-active="activeStream === stream.id"
        :stream-title="stream.title"
        @like-stream="likeStream"
        @active-stream="setActiveStream"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_STATE_ITEM, SET_ACTIVE_STREAM } from '../store/helpers/mutationTypes';
import firebase, { db } from '../config/Firebase';
import Stream from '../components/Stream';
import Overlay from '../components/Overlay';
import eventBus from '../utils/EventBus';
import GlobalEventHelper from '../mixins/GlobalEventHelper';

export default {
  name: 'Dashboard',
  mixins: [GlobalEventHelper],
  components: {
    Stream,
    Overlay,
  },
  data() {
    return {
      likes: [],
      colors: ['#FF2652', '#FF8930', '#33CC4D', '#0E3FF2'],
      activeStream: null,
      lastActiveStream: null,
      breaks: {},
      playOnlyCurrentStream: false,
      updateListenersInQueue: false,
    };
  },
  created() {
    eventBus.$on('toggleSpeakerMode', this.toggleSpeakerMode);
    // Does not work on mobile safari and on firefox when user closes browser works only sometimes.
    window.addEventListener('beforeunload', this.userLeavesBrowser);
  },
  beforeDestroy() {
    eventBus.$off('toggleSpeakerMode', this.toggleSpeakerMode);
    window.removeEventListener('beforeunload', this.userLeavesBrowser);
    this.userLeavesBrowser();
  },
  mounted() {
    // Only initialise the dashboard if the anonymous user is signed in
    // Otherwise we the user is not permitted to read/write firestore
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.initDashboard();
      }
    });
  },
  computed: {
    ...mapGetters([
      'streams',
      'breakId',
    ]),
    /**
     * @description Returns the current firestore document
     * @author Femke Buijs
     */
    currentFirestoreDocument() {
      return db.collection('breaks').doc(this.breakId);
    },
    // Get likes from localStorage
    streamLikes() {
      return sessionStorage.getItem('likes');
    },
    // Set color based on a v-for index
    setColor() {
      return (index) => this.colors[index];
    },
  },
  watch: {
    playOnlyCurrentStream(useSingleStream) {
      // Pause/play the streams
      this.setStreamState(useSingleStream);
    },
    activeStream(newState, oldValue) {
      // Create the updated muteStreams state array
      const mutedStreams = this.streams
        .map((stream) => stream.id)
        .filter((streamId) => streamId !== newState);

      // Update the mutedStreams key in our store
      this.$store.commit(SET_STATE_ITEM, {
        name: 'mutedStreams',
        data: mutedStreams,
      });

      // Pause/play the streams
      this.setStreamState(this.playOnlyCurrentStream);

      // Update listener amount in database, but only trigger once with a 60 sec debounce
      if (!this.updateListenersInQueue) {
        this.updateListenersInQueue = true;
        if (oldValue) {
          this.lastActiveStream = oldValue;
        }

        setTimeout(() => {
          this.updateListeners();
        }, 60000);
      }
    },
  },
  methods: {
    /**
     * @description Manage the play/pause state of our streams for
     * 'single stream mode/WIFI speaker' mode
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    setStreamState(useSingleStream) {
      this.streams.forEach((stream) => {
        if (!Object.keys(this.$refs).includes(stream.id)) return;

        // Retrieve our refs
        const refs = this.$refs[stream.id];

        refs.forEach((streamRef) => {
          if ((this.activeStream && stream.id === this.activeStream) || !useSingleStream) {
            // Start our stream
            streamRef.playVideo();
          } else {
            // Pause our video feed
            streamRef.pauseVideo();
          }
        });
      });
    },
    /**
     * @description Initialises the dashboard
     *
     * @author Femke Buijs
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.3
     */
    initDashboard() {
      // Dispatch a vueX action responsible to retrieve/fetch data from database
      // based on a current route to populate our vueX state
      this.$store.dispatch('setBreak', this.$route);

      // Retrieve likes from localStorage
      this.likes = this.streamLikes ? this.streamLikes.split(',') : [];
    },
    /**
     * @description Update the 'active' stream
     *
     * @author Robin Eijsbouts (@RobinEijsouts)
     * @since 1.0.3
     */
    setActiveStream(id) {
      if (id) {
        // update the activeStream with a current stream
        this.$store.commit(SET_ACTIVE_STREAM, {
          name: 'activeStream',
          data: id,
        });
        this.activeStream = id;
      }
    },
    /**
     * @description Updates the listener count in the database
     */
    updateListeners() {
      // Set this.updateListenersInQueue to false, since the update is being executed
      this.updateListenersInQueue = false;

      // If last active stream and current stream are the same, do not update database
      if (this.lastActiveStream === this.activeStream) return;

      const lastStream = this.streams.find((stream) => stream.id === this.lastActiveStream);

      // If there is a last active stream and its listener count is above 0,
      // decrease its listener count by 1
      if (this.lastActiveStream != null && lastStream.listeners > 0) {
        this.currentFirestoreDocument.update({
          [`rooms.${this.lastActiveStream}.listeners`]: firebase.firestore.FieldValue.increment(-1),
        });
      }
      // Increase currentStream's listener count by 1
      if (this.activeStream) {
        this.currentFirestoreDocument.update({
          [`rooms.${this.activeStream}.listeners`]: firebase.firestore.FieldValue.increment(1),
        });
      }
    },
    /**
     * @description If there is a currently active stream, there is no queued
     * database update and the current listener amount is larger than 0,
     * decrease the listener amount by one before page is unloaded
     */
    userLeavesBrowser() {
      const activeStream = this.streams.find((stream) => stream.id === this.activeStream);

      if (this.activeStream && !this.updateListenersInQueue && activeStream.listeners > 0) {
        this.currentFirestoreDocument.update({
          [`rooms.${this.activeStream}.listeners`]: firebase.firestore.FieldValue.increment(-1),
        });
      }
    },
    likeStream(streamIdentifier) {
      // If streamIdentifier is null do not execute the rest:
      if (!streamIdentifier) return;

      // Retrieve the object of the active stream
      const activeStream = this.streams.find((stream) => stream.id === streamIdentifier);

      // check if there some likes from this browser and proceed to add or remove (1) from Database
      if (!this.likes.includes(streamIdentifier)) {
        this.likes.push(streamIdentifier);

        this.currentFirestoreDocument.update({
          [`rooms.${streamIdentifier}.likes`]: firebase.firestore.FieldValue.increment(1),
        });
      } else if (activeStream.likes > 0) {
        this.likes = this.likes.filter((stream) => stream !== streamIdentifier);

        this.currentFirestoreDocument.update({
          [`rooms.${streamIdentifier}.likes`]: firebase.firestore.FieldValue.increment(-1),
        });
      }
      // update likes in local storage
      sessionStorage.setItem('likes', this.likes);
    },
    /**
     * @description toggles between playing only selected stream and playing all streams
     * @author Kristine Trona
     */
    toggleSpeakerMode(playOnlyCurrentStream) {
      this.playOnlyCurrentStream = playOnlyCurrentStream;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/imports/component';

.sd-video {
  position: relative;
  flex-basis: 50%;

  &__iframe {
    height: 100%;
  }

  @media (max-width: map-get($breakpoints, md)) and (orientation: portrait) {
    flex-basis: 100%;
  }
}
</style>
