<template>
  <div :ref="streamType" />
</template>

<script>
/**
 * @file
 * @description Create a Twitch player component
 *
 * @author Thiago Fazzi (@ThiagoFazzi)
 * @link https://dev.twitch.tv/docs/embed/video-and-clips
 */
import streamMixin from '../mixins/streamMixin';
import { isDesktop } from '../mixins/WindowUtils';
import GlobalEventHelper from '../mixins/GlobalEventHelper';

export default {
  name: 'Twitch',
  mixins: [streamMixin, GlobalEventHelper, isDesktop],
  data() {
    return {
      qualities: [],
    };
  },
  mounted() {
    this.createPlayer();
  },
  computed: {
    /**
     * @description Set the main options to create a Twitch Player
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.2
     */
    streamOptions() {
      return {
        width: this.width,
        height: this.height,
        channel: this.channel,
        allowfullscreen: true,
      };
    },
  },
  methods: {
    /**
     * @description Create an instance of Twitch Player
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.2
     */
    createPlayer() {
      this.StreamPlayer = new window.Twitch.Player(this.$refs[this.streamType], this.streamOptions);
      this.player.addEventListener('ready', () => {
        this.mute();

        // Set the volume as max volume because the
        // user can not control the audio volume
        // on mobile and otherwise we may get streams at
        // 0 volume aka 'muted' streams. which does not register as 'muted'
        // inside the player
        if (!this.isDesktop) {
          this.player.setVolume(1);
        }
      });
      this.player.addEventListener('playing', () => {
        this.qualities = this.player.getQualities();
        this.setStreamQuality();
      });
    },
    /**
     * @description Call Twitch player function 'play'
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.2
     */
    play() {
      if (this.player && !this.player.getEnded()) {
        this.player.play();
      }
    },
    /**
     * @description Call Twitch player function 'pause'
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.2
     */
    pause() {
      if (this.player) this.player.pause();
    },
    /**
     * @description Set Twitch Player to mute
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.2
     */
    mute() {
      if (this.player) this.player.setMuted(true);
    },
    /**
     * @description Set Twitch Player to unmute
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.2
     */
    unMute() {
      if (this.player) {
        this.player.setMuted(false);

        // Reset the volume to our default value/max volume
        // because otherwise we may 'unmute' the stream. However
        // the user could have 'set' the volume to 0 which means
        // that they do not get any sound, which can be seen as
        // 'bug'.
        if (this.player.getVolume() === 0) {
          this.player.setVolume(1);
        }
      }
    },
    /**
     * @description Return the 'muted' state of the video player/stream
     *
     * @author Robin Eijsbouts (@RobinEijsbouts)
     * @since 1.0.3
     */
    isMuted() {
      if (this.player) {
        return (this.player.getMuted() || this.player.getVolume() === 0);
      }

      // Return our 'default' state
      return true;
    },
    /**
     * @description Set Twitch stream quality based on device
     * default: mobile = auto, desktop = auto
     *
     * @author Thiago Fazzi (@ThiagoFazzi)
     * @since 1.0.2
     * @returns String
     */
    setStreamQuality() {
      if (this.qualities && this.quality) {
        const { mobile, desktop } = this.quality;

        const mobileQuality = mobile && this.qualities.some((quality) => quality.name === mobile)
          ? this.qualities.find((quality) => quality.name === mobile).group
          : 'auto';

        const desktopQuality = desktop && this.qualities.some((quality) => quality.name === desktop)
          ? this.qualities.find((quality) => quality.name === desktop).group
          : 'auto';

        if (mobileQuality && desktopQuality) {
          this.player.setQuality(this.isDesktop ? desktopQuality : mobileQuality);
        }
      }
    },
  },
};
</script>
