/**
 * @file
 * @description Spanish translation table file
 * @author      Femke Buijs
 */
export default {
  live: 'En directo',
  listenTo: 'Escuchar',
  isInitiativeOf: 'es una iniciativa de',

  // General translations
  previous: 'Anterior',
  next: 'Siguiente',
  stepNumber: 'Paso {number}:',
  share: 'Compartir',
  privacy: 'Privacidad',
  chat: 'Chat',
  help: 'Help',
  yes: 'si',
  no: 'no',
  faq: 'faq',

  // Colors
  red: 'Rojo',
  orange: 'Naranja',
  green: 'Verde',
  blue: 'Azul',

  // Add to Homescreen pop-up modal
  addToHomeScreenModalTitle: 'Faster to Silent Disco Break?',
  addToHomeScreenModalDescription: 'Add Silent Disco Break to your homescreen',

  // Tooltips
  tooltipSpeakerMode: 'Enable this mode if you have slow internet and/or if you\'re using WiFi speakers.',

  // Introduction modal

  // Slide 1
  introModalSlideOneTitle: 'Bienvenido a Silent Disco Break?',
  introModalSlideOneContent: 'Silent Disco Break te permite vivir la experiencia de una verdadera discoteca silenciosa desde la comodidad de tu propio salón o durante tu descanso en el trabajo. Ponte los auriculares y elige el DJ para que toque tus canciones favoritas.',

  // Slide 2
  introModalSlideTwoTitle: '¿Cómo funciona Silent Disco Break?',
  introModalSlideTwoListitemOne: 'Ponte los cascos o auriculares',
  introModalSlideTwoListitemTwo: 'Haz clic en uno de los DJ',
  introModalSlideTwoListitemThree: '¿Te gusta lo que escuchas? ¡Dale un 👍 al DJ!',
  introModalSlideTwoListitemFour: 'Cambia de DJ cuando quieras escuchar otra cosa',

  // Slide 4
  introModalSlideFourTitle: 'Privacidad y cookies',
  introModalSlideFourContent: 'Al presionar el botón, estará de acuerdo con nuestro <a href="{privacyLink}" class="sd-link" target="_blank">Política de privacidad</a> ans <a href="{cookieLink}" class="sd-link" target="_blank">Política de cookies</a>.',

  // Introduction modal buttons
  introModalFinalButton: 'Start Silent Disco',

  // Header
  speakerMode: 'WiFi speaker {break}or bad WiFi?',

  // Footer
  contactUs: 'Contacto',

  // Share modal/links
  shareOn: 'Compartir con {platform}',
  shareText: 'Ich tanze gerade zu Silent Disco Break, möchte mich mir anschließen?',

  // Privacy & cookie links
  // These are in a translation string because the privacy/cookie link is different
  // for each language
  privacyLink: 'https://www.iubenda.com/privacy-policy/39481799',
  cookieLink: 'https://www.iubenda.com/privacy-policy/39481799/cookie-policy',

  // Copyright copy
  copyright: '©2020 Silent Disco Break',

  // Help page

  // Question #1
  faqNoSound: 'I don’t hear any sound',
  faqNoSoundSubtitle: 'If you don’t hear any sound, do the following:',

  refreshPageWifiMode: 'Refresh the page and enable the speaker / poor wifi mode (switch at the top)',
  checkVolume: 'Check your volume',
  isDeviceMuted: 'Make sure your device is not muted',

  // Question #2
  faqBlackScreen: 'The streams are not loading or I see a black screen',

  // Question #3
  faqWifiSpeaker: 'I want to listen via my WiFi speaker (s)',

  disconnectWifiSpeaker: 'Disconnect your device from your WiFi speaker (s)',
  connectWifiSpeaker: 'Connect your device from your WiFi speaker (s)',

  // Question #4
  faqError: 'I see a 3000 error',

  openInPrivate: 'Open the website in an incognito (private) window and try again',
  emptyHistory: 'Or empty your internet history and files and try again',
  reloadPage: 'Reload the page',

  // Question #5
  faqBuffer: 'The stream falters (buffer) a lot',

  // Question #6
  faqRedirection: 'The page does not load and I see “Too many redirections”',

  incognitoTryAgain: 'Open the website in an incognito (private) window and try again',
  clearHistory: 'Or empty your internet history and files and try again',

  // FAQ re-used sentences
  refreshPageTapDj: 'Refresh the page and tap in a dj',
  openInIncognito: 'Open the website in an incognito (private) window and try the steps above',
  refreshEnableSpeakermode: 'Refresh the page and enable speaker mode (switch on top)',
};
