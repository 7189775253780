<template>
  <body class="sd-webapp-wrapper">
    <router-view class="sd-webapp-wrapper" />
    <!-- Display our 'add to homescreen' notice -->
    <Notice
      :title="$t('addToHomeScreenModalTitle')"
      :content="$t('addToHomeScreenModalDescription')"
      id="addToHomeScreenModal" />
  </body>
</template>

<script>
import { getCssVariable } from './mixins/WindowUtils';
import Notice from './components/Notice';

export default {
  name: 'App',
  components: {
    Notice,
  },
  mixins: [getCssVariable],
  created() {
    this.setLocale();
  },
  methods: {
    /**
     * Set app language
     * Default is always English, also when the browser language is not known in i18n
     * In case more languages will be added, the below needs to be changed
     *
     * @author Femke Buijs (@FemkeBuijs)
     */
    setLocale() {
      const browserLanguage = navigator.language || navigator.userLanguage || 'en';

      const locale = this.getLanguage(browserLanguage);

      this.$i18n.locale = locale;
    },
    /**
     * Returns the correct i18n language based on the browser language
     * @param {String} browserLanguage The language set in the browser settings
     *
     * @author Femke Buijs (@FemkeBuijs)
     */
    getLanguage(browserLanguage) {
      if (browserLanguage.includes('nl')) return 'nl';
      if (browserLanguage.includes('it')) return 'it';
      if (browserLanguage.includes('fr')) return 'fr';
      if (browserLanguage.includes('de')) return 'de';
      if (browserLanguage.includes('es')) return 'es';
      return 'en';
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/styles/imports/component';
  @import '@/assets/styles/components/base';
</style>

<style lang="scss" scoped>
  /* stylelint-disable-next-line no-duplicate-at-import-rules */
  @import '@/assets/styles/imports/component';

  .sd-webapp-wrapper {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-family: $font-family-primary;
    color: $color-white;
    text-align: center;
  }
</style>
